<template lang="pug">
v-list-item.targeted-poi.px-2
  v-list-item-action.my-0.mr-0
    v-tooltip(top dark color='secondary')
      template(#activator='{ on }')
        v-btn.inclusion-btn(
          v-on='on'
          icon
          small
          :color='isIncluded ? "success" : "error"'
          @click='onInclusionChange'
        )
          v-icon.inclusion-btn-icon {{ isIncluded ? 'mdi-plus-box' : 'mdi-minus-circle' }}
      span.text-caption {{ isIncluded ? 'Exclude' : 'Include' }} venues in promixity
  v-list-item-content
    .d-flex.align-center
      .d-flex.justify-end.pr-2(:style="{ 'min-width': radiusInputSize }")
        select-radius(
          geoType='pointOfInterest'
          :value='radius'
          :unit='unit'
          @input='onRadiusChange'
        )
      v-select.poi-input(
        :items='sortedAvailablePointsOfInterest'
        item-text='name'
        label='Select POI'
        dense
        single-line
        filled
        rounded
        hide-details
        return-object
        :value='pointOfInterest'
        @input='onPointOfInterestChange'
      )
  v-list-item-action.my-1.ml-1
    v-btn.remove-poi-btn(icon small @click='onRemove')
      v-icon(color='grey') mdi-close
</template>

<script lang="ts">
import SelectRadius from '@/components/SelectRadius.vue';
import { PointOfInterestApiPoco } from '@/types/PointOfInterestTypes';
import { allUnits, Unit } from '@/types/GeolocationTypes';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'PointOfInterestItem',
  components: { SelectRadius },
  props: {
    availablePointsOfInterest: {
      type: Array as PropType<PointOfInterestApiPoco[]>,
      required: true,
    },
    isIncluded: {
      type: Boolean,
      required: true,
    },
    pointOfInterest: {
      type: Object as PropType<PointOfInterestApiPoco | null>,
      default: null,
    },
    radius: {
      type: String,
      required: true,
    },
    unit: {
      type: String as PropType<Unit>,
      validator: (val: Unit) => allUnits.includes(val),
      default: 'metric',
    },
  },
  emits: {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    inclusionChanged: () => true,
    radiusChanged: (_value: string) => true,
    pointOfInterestChanged: (_value: PointOfInterestApiPoco) => true,
    removed: () => true,
    /* eslint-enable @typescript-eslint/no-unused-vars */
  },
  computed: {
    sortedAvailablePointsOfInterest() {
      return [...this.availablePointsOfInterest].sort((a, b) => a.name.localeCompare(b.name));
    },
    radiusInputSize() {
      return this.unit === 'metric' ? '86px' : '92px';
    },
  },
  methods: {
    onInclusionChange() {
      this.$emit('inclusionChanged');
    },
    onRadiusChange(radius: string) {
      this.$emit('radiusChanged', radius);
    },
    onPointOfInterestChange(pointOfInterest: PointOfInterestApiPoco) {
      this.$emit('pointOfInterestChanged', pointOfInterest);
    },
    onRemove() {
      this.$emit('removed');
    },
  },
});
</script>

<style scoped>
.poi-input {
  width: 0px;
}
</style>
