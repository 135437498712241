<template lang="pug">
div
  #label.pl-0.py-1.text-body-2(v-if='label') {{ label }}

  v-list.pt-0(v-if='targetedPointsOfInterest.length || showQueuedTargetedPointOfInterest')
    div(v-for='item in targetedPointsOfInterest' :key='item.pointOfInterest.id')
      point-of-interest-item(
        :available-points-of-interest='addSelectedPoiToAvailablePois(item.pointOfInterest)'
        :is-included='item.isIncluded'
        :radius='item.radius'
        :point-of-interest='item.pointOfInterest'
        :unit='unit'
        @inclusionChanged='changeTargetedPoiInclusion(item)'
        @radiusChanged='(radius) => changeTargetedPoiRadius(item, radius)'
        @pointOfInterestChanged='(poi) => changeTargetedPoiPointOfInterest(item, poi)'
        @removed='removeTargetedPointOfInterest(item)'
      )
      v-divider

    v-scroll-y-transition(hide-on-leave)
      div(v-if='showQueuedTargetedPointOfInterest')
        point-of-interest-item(
          :available-points-of-interest='availablePointsOfInterest'
          :is-included='queuedTargetedPointOfInterest.isIncluded'
          :radius='queuedTargetedPointOfInterest.radius'
          :unit='unit'
          @inclusionChanged='queuedTargetedPointOfInterest.isIncluded = !queuedTargetedPointOfInterest.isIncluded'
          @radiusChanged='(radius) => queuedTargetedPointOfInterest.radius = radius ? radius : queuedTargetedPointOfInterest.radius'
          @pointOfInterestChanged='addQueuedTargetedPoiToTargetedPois'
          @removed='showQueuedTargetedPointOfInterest = false'
        )
        v-divider

  v-btn#add-poi-button.ma-0(text color='primary' :disabled='!canAddPointOfInterest' @click='queueTargetedPointOfInterest')
    v-icon(left) mdi-plus
    | Add Point of Interest
</template>

<script lang="ts">
import PointOfInterestItem from '@/components/PointOfInterestItem.vue';
import { PointOfInterestApiPoco, TargetedPointOfInterest } from '@/types/PointOfInterestTypes';
import { allUnits, Unit } from '@/types/GeolocationTypes';
import { defineComponent, PropType } from 'vue';
import RadiusService from '@/services/RadiusService';

type QueuedTargetedPointOfInterest = Omit<TargetedPointOfInterest, 'pointOfInterest'>;

export default defineComponent({
  name: 'PointsOfInterest',
  components: { PointOfInterestItem },
  props: {
    label: {
      type: String,
      default: '',
    },
    pointsOfInterest: {
      type: Array as PropType<PointOfInterestApiPoco[]>,
      required: true,
    },
    targetedPointsOfInterest: {
      type: Array as PropType<TargetedPointOfInterest[]>,
      required: true,
    },
    unit: {
      type: String as PropType<Unit>,
      validator: (val: Unit) => allUnits.includes(val),
      default: 'metric',
    },
  },
  emits: {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    'update:targetedPointsOfInterest': (_value: TargetedPointOfInterest[]) => true,
    addPointOfInterestClicked: () => true,
    pointOfInterestSelected: (_value: string) => true,
    /* eslint-enable @typescript-eslint/no-unused-vars */
  },
  data() {
    const queuedTargetedPointOfInterest: QueuedTargetedPointOfInterest = {
      radius: '',
      isIncluded: true,
    };

    return {
      queuedTargetedPointOfInterest,
      showQueuedTargetedPointOfInterest: false,
    };
  },
  computed: {
    availablePointsOfInterest() {
      const targetedPoiIds = this.targetedPointsOfInterest.map((targetedPoi) => targetedPoi.pointOfInterest.id);
      return this.pointsOfInterest.filter((poi) => !targetedPoiIds.includes(poi.id));
    },
    canAddPointOfInterest() {
      return !this.showQueuedTargetedPointOfInterest && this.availablePointsOfInterest.length > 0;
    },
  },
  methods: {
    addSelectedPoiToAvailablePois(selectedPointOfInterest: PointOfInterestApiPoco) {
      return [...this.availablePointsOfInterest, selectedPointOfInterest];
    },
    queueTargetedPointOfInterest() {
      const defaultRadius = RadiusService.defaultRadiuses('pointOfInterest', this.unit);
      if (!defaultRadius) return;

      this.queuedTargetedPointOfInterest = {
        radius: defaultRadius,
        isIncluded: true,
      };
      this.showQueuedTargetedPointOfInterest = true;

      this.$emit('addPointOfInterestClicked');
    },
    addQueuedTargetedPoiToTargetedPois(selectedPointOfInterest: PointOfInterestApiPoco) {
      this.showQueuedTargetedPointOfInterest = false;

      const targetedPointOfInterest = {
        pointOfInterest: selectedPointOfInterest,
        radius: this.queuedTargetedPointOfInterest.radius,
        isIncluded: this.queuedTargetedPointOfInterest.isIncluded,
      };
      this.updateTargetedPointsOfInterest([...this.targetedPointsOfInterest, targetedPointOfInterest]);

      this.emitPointOfInterestSelected(selectedPointOfInterest);
    },
    changeTargetedPoiInclusion(targetedPoiToUpdate: TargetedPointOfInterest) {
      const newTargetedPointsOfInterest = this.targetedPointsOfInterest.map((targetedPoi) =>
        targetedPoi.pointOfInterest.id === targetedPoiToUpdate.pointOfInterest.id
          ? { ...targetedPoi, isIncluded: !targetedPoi.isIncluded }
          : targetedPoi
      );
      this.updateTargetedPointsOfInterest(newTargetedPointsOfInterest);
    },
    changeTargetedPoiRadius(targetedPoiToUpdate: TargetedPointOfInterest, radius: string) {
      const newTargetedPointsOfInterest = this.targetedPointsOfInterest.map((targetedPoi) =>
        targetedPoi.pointOfInterest.id === targetedPoiToUpdate.pointOfInterest.id ? { ...targetedPoi, radius } : targetedPoi
      );
      this.updateTargetedPointsOfInterest(newTargetedPointsOfInterest);
    },
    changeTargetedPoiPointOfInterest(targetedPoiToUpdate: TargetedPointOfInterest, pointOfInterest: PointOfInterestApiPoco) {
      const newTargetedPointsOfInterest = this.targetedPointsOfInterest.map((targetedPoi) =>
        targetedPoi.pointOfInterest.id === targetedPoiToUpdate.pointOfInterest.id
          ? { ...targetedPoi, pointOfInterest }
          : targetedPoi
      );
      this.updateTargetedPointsOfInterest(newTargetedPointsOfInterest);

      this.emitPointOfInterestSelected(pointOfInterest);
    },
    removeTargetedPointOfInterest(targetedPoiToRemove: TargetedPointOfInterest) {
      const newTargetedPointsOfInterest = this.targetedPointsOfInterest.filter(
        (targetedPoi) => targetedPoi.pointOfInterest.id !== targetedPoiToRemove.pointOfInterest.id
      );
      this.updateTargetedPointsOfInterest(newTargetedPointsOfInterest);
    },
    emitPointOfInterestSelected(pointOfInterest: PointOfInterestApiPoco) {
      this.$emit('pointOfInterestSelected', pointOfInterest.name);
    },
    updateTargetedPointsOfInterest(value: TargetedPointOfInterest[]) {
      this.$emit('update:targetedPointsOfInterest', value);
    },
  },
});
</script>
