<template lang="pug">
v-sheet.card.rounded.py-2.px-3.cursor-pointer(
  :elevation='elevation'
  @click='handleCardClick'
)
  v-checkbox.checkbox.mt-0.pt-0(
    :label='label'
    :input-value='isChecked'
    hide-details
    readonly
    color='primary'
  )
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CheckboxCard',
  props: {
    elevation: {
      type: Number,
      default: 2,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    input: (_value: boolean) => true,
    /* eslint-enable @typescript-eslint/no-unused-vars */
  },
  data() {
    return {
      isChecked: this.value,
    };
  },
  watch: {
    value(newVal: boolean) {
      this.isChecked = newVal;
    },
  },
  methods: {
    handleCardClick() {
      this.isChecked = !this.isChecked;
      this.$emit('input', this.isChecked);
    },
  },
});
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
