﻿<template lang="pug">
v-card#forecast-inventory-summary.d-flex.align-center(height=44 elevation="2")
  div.d-flex.ml-2
    v-tooltip(bottom dark color="secondary" max-width="285")
      template(v-slot:activator="{on}")
        div.d-flex.align-center.mr-1(v-on="on")
          v-icon.primary--text( color="primary") mdi-map-marker
          v-sheet(min-width="53")
            v-scroll-y-reverse-transition
              div.primary--text.text-h6#targeted-venues(v-if="!loading") {{ formatNumber(numberOfVenues) }}
      div.text-left.text-caption
        | Targeted Venues
    v-tooltip(bottom dark color="secondary" max-width="285")
      template(v-slot:activator="{on}")
        div.d-flex.align-center(v-on="on")
          v-icon.px-1.text--secondary() mdi-television
          v-sheet(min-width="53")
            v-scroll-y-reverse-transition
              div.text--secondary.text-h6#targeted-screens(v-if="!loading") {{ formatNumber(numberOfScreens) }}
      div.text-left.text-caption
        | Targeted Screens
  v-btn(v-if="showButton" large text color="primary" @click="clickButton()")
    | {{ buttonLabel }}
</template>

<script lang="ts">
import NumberHelper from '@/services/NumberHelper';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ForecastInventorySummary',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    numberOfVenues: {
      type: Number,
      default: 0,
    },
    numberOfScreens: {
      type: Number,
      default: 0,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    buttonLabel: {
      type: String,
      default: '',
    },
  },
  emits: {
    clickButton: () => true,
  },
  methods: {
    clickButton() {
      this.$emit('clickButton');
    },
    formatNumber(number: number) {
      return NumberHelper.shortenNumber(number, 1000, 0);
    },
  },
});
</script>
