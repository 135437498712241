<template lang="pug">
v-hover(v-slot='{ hover }' v-model="isPopupHovered")
  v-card.venue-popup(
    dark
    elevation='24'
    :loading='loading'
    :width='isCompact ? 300 : 400'
  )
    template(v-slot:progress='')
      .venue-loading.d-flex.fill-height.align-center.justify-center.ma-0.popup-height
        v-progress-circular(indeterminate color='primary')

    v-img.venue-image(v-if='!loading' :src='venue.image' :title='venue.name' style='position:relative;' height='250px')
      .bottom-gradient.fill-height
      v-fade-transition
        .bottom-gradient-hover.fill-height(v-if='hover' class='v-card--reveal transition-fast-in-fast-out')
      
      .venue-currently-unavailable(style='position:absolute; top:8px; right:8px; z-index:20;')
        v-tooltip(v-if='isRecentlyUnavailable' v-model='isTooltipActive' top color='secondary' max-width='300')
          template(v-slot:activator='{ on }')
            v-chip(color='warning' x-small v-on='on')
              span.text-uppercase Currently Unavailable
          .text-caption This venue hasn't sent any ad request in the past week, suggesting it's either currently sold out or experiencing technical issues.

      v-container.fill-height.pb-2
        v-row.my-0.fill-height(align='end' style='position:relative; z-index:10;')
          v-col.pb-0(cols='12')
            div
              .text-overline.white--text.venue-network {{ venue.network }}
              .venue-name.text-h6.white--text(:class='{ "one-line-truncate": !hover }') {{ venue.name }}
              v-expand-transition
                .venue-hovered-wrapper(v-if='hover' class='transition-fast-in-fast-out')
                  .venue-address.text-caption.grey--text {{ venueAddress }}
                  .venue-actions(v-if='showActionButtons')
                    v-divider.my-2
                    .d-flex.justify-center
                      v-btn.venue-action-exclude(text dark @click='exclude')
                        v-icon(color='error') mdi-minus-circle
                        | &nbsp;Exclude
                      v-btn.venue-action-include(text dark @click='include')
                        v-icon(color='success') mdi-plus-box
                        | &nbsp;Include
</template>

<script lang="ts">
import VenueService from '@/services/VenueService';
import { Venue } from '@/types/GeolocationTypes';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'VenuePopup',
  props: {
    getVenueDetailsFunc: {
      type: Function as PropType<(venueId: number) => Promise<Venue>>,
      required: true,
    },
    isCompact: {
      type: Boolean,
      default: false,
    },
    showActionButtons: {
      type: Boolean,
      default: true,
    },
    venueId: {
      type: Number as PropType<number | null>,
      default: null,
    },
    venueSource: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
  emits: {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    excludeVenue: (_venue: Venue) => true,
    includeVenue: (_venue: Venue) => true,
    /* eslint-enable @typescript-eslint/no-unused-vars */
  },
  data() {
    return {
      isPopupHovered: false,
      isTooltipActive: false,
      loading: true,
      venue: null as Venue | null,
    };
  },
  computed: {
    isRecentlyUnavailable() {
      if (this.venueSource) {
        return ['includedRecentlyUnavailableVenues', 'excludedRecentlyUnavailableVenues'].includes(this.venueSource);
      } else {
        return false;
      }
    },
    venueAddress() {
      return this.venue ? VenueService.venueAddressInline(this.venue) : '';
    },
  },
  watch: {
    async venueId() {
      if (this.venueId) {
        await this.getVenueDetails(this.venueId);
      } else {
        this.loading = true;
        this.venue = null;
      }
    },
  },
  async created() {
    if (this.venueId) {
      await this.getVenueDetails(this.venueId);
    }
  },
  methods: {
    async getVenueDetails(venueId: number) {
      this.loading = true;

      const venueDetails = await this.getVenueDetailsFunc(venueId);
      if (venueDetails && venueDetails.id === this.venueId) {
        this.venue = venueDetails;
        this.loading = false;
      }
    },
    exclude() {
      if (this.venue) {
        this.$emit('excludeVenue', this.venue);
      }
    },
    include() {
      if (this.venue) {
        this.$emit('includeVenue', this.venue);
      }
    },
  },
});
</script>

<style scoped>
.popup-height {
  height: 250px;
}
.bottom-gradient {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.9) 0%, transparent 100px);
  position: absolute;
  width: 100%;
}
.bottom-gradient-hover {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, transparent 300px);
  position: absolute;
  width: 100%;
  bottom: 0px;
}
.one-line-truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
