<template lang="pug">
v-menu(offset-y max-height='300px' :attach='attach')
  template(v-slot:activator="{on}")
    v-btn.pr-0(
      class="pr-0 transparent"
      small
      color='primary'
      text
      ripple
      @click="handleMenuButtonClick($event, on.click)"
    ) {{ selectedValue }}
      v-icon.mr-0() mdi-menu-down
      
  v-list-item-group#menu-item-group(v-model="selectedValue" mandatory)
    v-list(dense width=140)
      v-list-item(
        v-for='(item, index) in possibleValues'
        :key='index'
        :value='item.label'
        :ref='item.label'
        :disabled='item.disabled'
        :inactive="item.disabled"
      )
        v-list-item-title {{ item.label }}
        v-progress-circular(
          v-if='item.hasLoader'
          size='16',
          width='2',
          color='primary',
          indeterminate)
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { allGeolocationTypes, allUnits, GeoJsonData, GeolocationType, Unit } from '@/types/GeolocationTypes';
import RadiusService from '@/services/RadiusService';

export default defineComponent({
  name: 'SelectRadius',
  props: {
    value: {
      type: String,
      required: true,
    },
    unit: {
      type: String as PropType<Unit>,
      validator: (val: string) => allUnits.includes(val as Unit),
      default: 'metric',
    },
    geoType: {
      type: String as PropType<GeolocationType | 'pointOfInterest'>,
      validator: (val: string) => [...allGeolocationTypes, 'pointOfInterest'].includes(val as GeolocationType),
      default: 'address',
    },
    geoJsonData: {
      type: Object as PropType<GeoJsonData>,
      default: null,
    },
    isGeoSelectionDisabledFunc: {
      type: Function as PropType<() => Promise<boolean>>,
      default: () => Promise.resolve(false),
    },
    attach: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: string) => true,
  },
  data() {
    return {
      geoSelectionDisabled: true,
      geoSelectionAvailabilitySet: false,
    };
  },
  computed: {
    possibleValues() {
      const availableRadiuses = RadiusService.availableRadiuses(this.geoType, this.unit);

      return availableRadiuses.map((radius) => {
        const disableSelection =
          this.geoSelectionDisabled && RadiusService.geoRadiuses().includes(radius) && radius !== this.value;

        return disableSelection
          ? { label: `N/A - ${radius}`, disabled: true, hasLoader: !this.geoSelectionAvailabilitySet }
          : { label: radius, disabled: false, hasLoader: false };
      });
    },
    selectedValue: {
      get() {
        return this.value;
      },
      set(selected: string) {
        this.$emit('input', selected);
      },
    },
  },
  watch: {
    geoJsonData: {
      handler(newVal, oldVal) {
        if (newVal?.geoJson !== oldVal?.geoJson || newVal?.geoJsonFileUrl !== oldVal?.geoJsonFileUrl)
          this.geoSelectionAvailabilitySet = false;
      },
      deep: true,
    },
  },
  methods: {
    async handleMenuButtonClick(event: MouseEvent, onClick: (e: MouseEvent) => void) {
      onClick(event);
      if (!this.geoSelectionAvailabilitySet) {
        this.geoSelectionDisabled = await this.isGeoSelectionDisabledFunc();
        this.geoSelectionAvailabilitySet = true;
      }
    },
  },
});
</script>
