<template lang="pug">
div
  v-card#default-view.d-flex.align-center.justify-space-between.py-1.px-3(v-if='!showEditingView' elevation='12')
    span#label.d-inline-block.text-truncate.text-body-1 {{ geoTarget.geolocation.label }}
    div.d-flex.ml-2
      v-tooltip(v-if='canEditGeoTargetPosition' bottom)
        template(#activator='{ on, attrs }')
          v-btn#edit-icon-btn(icon v-bind='attrs' v-on='on' @click='expandPopup')
            v-icon(small) mdi-pencil
        span.caption Edit
      v-tooltip(v-if='canRemoveGeoTarget' bottom)
        template(#activator='{ on, attrs }')
          v-btn#remove-icon-btn(icon v-bind='attrs' v-on='on' @click='onRemoveButtonClick')
            v-icon(small) mdi-delete
        span.caption Remove

  v-card#editing-view(v-show='showEditingView' elevation='12' :width='isCompact ? 300 : 330')
    v-card-text
      v-form#edit-geo-target-form(v-model='isFormValid')
        v-text-field#location-name-input(
          label='Location Name'
          v-model='label'
          :disabled='isCityOrVenueOnly'
          :rules='[validateNonEmptyValue]'
        )
        v-text-field#lat-lng-input(
          label='Latitude, Longitude'
          v-model='coordinates'
          :disabled='isCityOrVenueOnly'
          :rules='[validateNonEmptyValue, validateCoordinates]'
        )
          template(#append='')
            select-radius(
              v-if='geoTarget && radius'
              attach
              :geoType='geoTarget.geolocation.type'
              :value='radius'
              :unit='unit'
              :is-geo-selection-disabled-func="isGeoSelectionDisabled"
              :geo-json-data="geoJsonData"
              @input='updateRadius'
            )
    v-card-actions
      v-spacer
      v-btn#cancel-btn(text @click='onCancelButtonClick') cancel
      v-btn#remove-btn(text @click='onRemoveButtonClick') remove
      v-btn#update-btn(text @click='onUpdateButtonClick' :disabled='!geoTargetChanged || !isFormValid' color='primary') update
</template>

<script lang="ts">
import SelectRadius from '@/components/SelectRadius.vue';
import GeoService from '@/services/GeoService';
import { allUnits, GeoJsonData, GeoTarget, ResolveGeoJsonFunc, Unit } from '@/types/GeolocationTypes';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'GeoTargetPopup',
  components: { SelectRadius },
  props: {
    canEditGeoTargetPosition: {
      type: Boolean,
      default: true,
    },
    canRemoveGeoTarget: {
      type: Boolean,
      default: true,
    },
    geoTarget: {
      type: Object as PropType<GeoTarget>,
      required: true,
    },
    isCompact: {
      type: Boolean,
      default: false,
    },
    unit: {
      type: String as PropType<Unit>,
      validator: (val: Unit) => allUnits.includes(val),
      default: 'metric',
    },
    resolveGeoJsonFunc: {
      type: Function as PropType<ResolveGeoJsonFunc>,
      required: true,
    },
  },
  emits: {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    expandedPopup: () => true,
    cancel: () => true,
    remove: () => true,
    update: (_value: GeoTarget) => true,
    /* eslint-enable @typescript-eslint/no-unused-vars */
  },
  data() {
    return {
      showEditingView: false,
      label: '',
      coordinates: '',
      radius: '',
      isFormValid: true,
    };
  },
  computed: {
    isCityOrVenueOnly() {
      return this.radius.includes('Only');
    },
    coordinatesChanged() {
      const lngLat = GeoService.tryParseCoordinates(this.coordinates);

      return (
        lngLat?.latitude !== this.geoTarget.geolocation.geography.latitude ||
        lngLat?.longitude !== this.geoTarget.geolocation.geography.longitude
      );
    },
    labelChanged() {
      return this.label !== this.geoTarget.geolocation.label;
    },
    radiusChanged() {
      return this.radius !== this.geoTarget.radius;
    },
    geoTargetChanged() {
      return this.coordinatesChanged || this.labelChanged || this.radiusChanged;
    },
    geoJsonData(): GeoJsonData {
      return {
        geoJson: this.geoTarget.geolocation.geography.geoJSON,
        geoJsonFileUrl: this.geoTarget.geolocation.geography.geoJSONFileUrl,
      };
    },
  },
  watch: {
    geoTarget(newValue: GeoTarget, oldValue: GeoTarget) {
      if (newValue.geolocation.value !== oldValue.geolocation.value) {
        this.showEditingView = false;

        this.resetLabel();
        this.resetCoordinates();
        this.resetRadius();
      }
    },
  },
  created() {
    this.resetLabel();
    this.resetCoordinates();
    this.resetRadius();
  },
  methods: {
    validateNonEmptyValue(value: string) {
      return !!value.trim() || 'Required.';
    },
    validateCoordinates(coordinates: string) {
      return !!GeoService.tryParseCoordinates(coordinates) || 'Coordinates are not valid.';
    },
    resetLabel() {
      this.label = this.geoTarget.geolocation.label;
    },
    resetCoordinates() {
      this.coordinates = `${this.geoTarget.geolocation.geography.latitude}, ${this.geoTarget.geolocation.geography.longitude}`;
    },
    resetRadius() {
      this.radius = this.geoTarget.radius ?? '';
    },
    expandPopup() {
      this.showEditingView = true;
      this.$emit('expandedPopup');
    },
    updateRadius(radius: string) {
      this.radius = radius;

      if (this.isCityOrVenueOnly && this.geoTarget) {
        this.resetLabel();
        this.resetCoordinates();
      }
    },
    onCancelButtonClick() {
      this.$emit('cancel');
    },
    onRemoveButtonClick() {
      this.$emit('remove');
    },
    onUpdateButtonClick() {
      const lngLat = GeoService.tryParseCoordinates(this.coordinates);
      if (!this.geoTarget || !lngLat) return;

      const type = this.labelChanged || this.coordinatesChanged ? 'address' : this.geoTarget.geolocation.type;
      const value = type === 'address' ? `${lngLat.latitude}, ${lngLat.longitude}` : this.geoTarget.geolocation.value;

      const updatedGeoTarget: GeoTarget = {
        geolocation: {
          geography: {
            geoJSON: this.geoTarget.geolocation.geography.geoJSON,
            geoJSONFileUrl: this.geoTarget.geolocation.geography.geoJSONFileUrl,
            latitude: lngLat.latitude,
            longitude: lngLat.longitude,
          },
          label: this.label,
          type: type,
          value: value,
        },
        isIncluded: this.geoTarget.isIncluded,
        radius: this.radius,
      };
      this.$emit('update', updatedGeoTarget);
    },
    async isGeoSelectionDisabled() {
      return !(await GeoService.isGeoSelectionAvailable(this.geoTarget, this.resolveGeoJsonFunc));
    },
  },
});
</script>
