<template lang="pug">
div
  v-card
    v-card-title.text-h5 All Locations
    div.pt-3.px-2.d-flex.justify-space-between
      v-btn.clear-all.ml-auto(text color="primary" @click="clearAllGeoTargets()")  Clear All
    v-virtual-scroll.virtual-scroll-list(:items="locations" item-height="60" height="400")
      template(v-slot:default="{ item: geoTarget, index: idx }")
        geo-target-element(
          :geo-target="geoTarget"
          :resolve-geo-json-func="resolveGeoJsonFunc"
          @updateRadius="newRadius => updateRadius(idx, newRadius)"
          @updateInclusion="isIncluded => updateInclusion(idx, isIncluded)"
          @delete="deleteTarget(idx)"
          @select="selectTarget(idx)"
          :unit="unit")
        v-divider  
    v-card-actions
      v-spacer
      v-btn.view-all-close(text @click="closeDialog") Close
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { allUnits, Unit, GeoTarget, ResolveGeoJsonFunc } from '@/types/GeolocationTypes';
import GeoTargetElement from '@/components/GeoTargetElement.vue';

export default defineComponent({
  name: 'AllLocations',
  components: {
    GeoTargetElement,
  },
  props: {
    locations: {
      type: Array as PropType<GeoTarget[]>,
      required: true,
    },
    unit: {
      type: String as PropType<Unit>,
      validator: (val: string) => allUnits.includes(val as Unit),
      default: 'metric',
    },
    resolveGeoJsonFunc: {
      type: Function as PropType<ResolveGeoJsonFunc>,
      required: true,
    },
  },
  emits: {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    updateRadius: (_idx: number, _value: string) => true,
    updateInclusion: (_idx: number, _value: boolean) => true,
    delete: (_idx: number) => true,
    select: (_idx: number) => true,
    clearAll: () => true,
    closeDialog: () => true,
    /* eslint-enable @typescript-eslint/no-unused-vars */
  },
  methods: {
    updateRadius(idx: number, value: string) {
      this.$emit('updateRadius', idx, value);
    },
    updateInclusion(idx: number, value: boolean) {
      this.$emit('updateInclusion', idx, value);
    },
    deleteTarget(idx: number) {
      this.$emit('delete', idx);
    },
    selectTarget(idx: number) {
      this.$emit('select', idx);
    },
    clearAllGeoTargets() {
      this.$emit('clearAll');
      this.closeDialog();
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
  },
});
</script>
