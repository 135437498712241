<template lang="pug">
v-autocomplete.geo-autocomplete(
  ref='geo-autocomplete'
  v-model='selectedGeolocation'
  clearable
  dense
  filled
  hide-details
  hide-no-data
  item-text='label'
  return-object
  rounded
  single-line
  :autofocus='!!initialValue'
  :class="{ 'edit': !!initialValue }"
  :filter='() => true'
  :items='items'
  :label='label'
  :menu-props='menuProps'
  :search-input.sync='searchInput'
  @click:clear='clearField'
)
  template(v-slot:prepend-inner='')
    v-fade-transition.d-block(leave-absolute)
    v-progress-circular.ml-n1.mr-3.loading(v-if='loading', size='20', width='2', color='primary', indeterminate='')
    v-icon.ml-n1.mr-2(v-else) mdi-map-marker-plus

  template(v-slot:item='{ item }')
    v-list-item-content
      v-list-item-title.search-result
        v-icon(color="primary") {{ item.type === 'venue' ? 'mdi-map-marker' : 'mdi-pin' }}
        |  {{ item.label }}

  template(v-slot:append='')
    v-tooltip(v-if="showBulkUpload" top dark color='secondary' max-width='300')
      template(v-slot:activator='{ on: tooltip }')
        v-btn.bulk-upload-btn.mr-n3(icon style='margin-top: -7px;' v-on='{ ...tooltip }' @click='openBulkUpload')
          v-icon mdi-playlist-plus
      .text-caption
        | Enter up to {{ maxBulkUploadEntries.toLocaleString() }} locations
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { debounce } from 'lodash';

import { LocationApiPoco } from '@/types/GeolocationTypes';

export default defineComponent({
  name: 'GeoAutocomplete',
  props: {
    clearFieldUponSelect: {
      type: Boolean,
      default: true,
    },
    geolocations: {
      type: Array as PropType<Array<LocationApiPoco>>,
      default: () => [],
    },
    initialValue: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Search Locations',
    },
    showBulkUpload: {
      type: Boolean,
      default: true,
    },
    maxBulkUploadEntries: {
      type: Number,
      validator: (value: number) => value > 0,
      default: 250,
    },
  },
  emits: {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    openBulkUpload: () => true,
    geolocationSelected: (_selectedGeolocation: LocationApiPoco) => true,
    search: (_query: string) => true,
    /* eslint-enable @typescript-eslint/no-unused-vars */
  },
  data() {
    return {
      items: [] as Array<LocationApiPoco>,
      menuProps: {
        maxHeight: 600,
        transition: 'scroll-y-reverse-transition',
        'nudge-top': -8,
      },
      searchInput: '',
      searchQuery: '',
      selectedGeolocation: null as LocationApiPoco | null,
    };
  },
  watch: {
    geolocations() {
      this.items = this.geolocations;

      if (this.initialValue && this.initialValue === this.searchQuery) {
        this.searchInput = this.initialValue;
      }
    },
    searchInput(val: string) {
      this.debouncedSearch(val);
    },
    selectedGeolocation: async function () {
      if (this.selectedGeolocation) {
        if (this.selectedGeolocation.label !== this.initialValue) {
          this.$emit('geolocationSelected', this.selectedGeolocation);

          if (this.clearFieldUponSelect) {
            // Allow v-autocomplete to finish its update before clearing it
            await this.$nextTick();
            this.clearField();
          }
        }
      }
    },
  },
  created() {
    this.debouncedSearch = debounce(this.debouncedSearch, 400);
    this.initialize();
  },
  methods: {
    debouncedSearch(val: string) {
      this.search(val);
    },
    search(val: string) {
      if (val && val !== this.searchQuery) {
        this.searchQuery = val;
        this.$emit('search', val);
      }
    },
    clearField() {
      this.items = [];
      this.searchInput = '';
      this.searchQuery = '';
      this.selectedGeolocation = null;
    },
    initialize() {
      if (this.initialValue) {
        this.search(this.initialValue);
      }
    },
    openBulkUpload() {
      this.$emit('openBulkUpload');
    },
  },
});
</script>
