<template lang="pug">
div
  v-scale-transition(origin="center center")
    div(v-if='isMounted')
      v-btn(:id='id' @click='clicked' :color='color' fab text :width='size' :height='size')
        v-progress-circular(
          class="transition-fast-in-fast-out map-cluster"
          ref='cluster-progress'
          rotate='360'
          style='background-color: rgba(255, 255, 255, 0.9); border-radius:50%'
          :class='elevationClass'
          :color='color'
          :size='size'
          :value='value'
          :width='width'
          )
          .cluster-title(:class='titleClass') {{ title }}
</template>

<script lang="ts">
import NumberHelper from '@/services/NumberHelper';
import { defineComponent } from 'vue';
import { VBtn, VProgressCircular, VScaleTransition } from 'vuetify/lib/components';

export default defineComponent({
  name: 'MapCluster',
  components: {
    VBtn,
    VProgressCircular,
    VScaleTransition,
  },
  emits: {
    clusterClicked: () => true,
  },
  data() {
    return {
      id: '',
      isMounted: false,
      sizeLevels: [5000, 500, 50, 9],
      targeted: 0,
      total: 0,
    };
  },
  computed: {
    color() {
      return this.targeted === 0 ? '#e0e0e0' : 'primary';
    },
    elevationClass() {
      return this.total >= this.sizeLevels[0] ? 'elevation-24' : this.total >= this.sizeLevels[1] ? 'elevation-8' : 'elevation-2';
    },
    size() {
      return this.total >= this.sizeLevels[0]
        ? 100
        : this.total >= this.sizeLevels[1]
        ? 70
        : this.total <= this.sizeLevels[3]
        ? 35
        : 50;
    },
    value() {
      return this.total > 0 ? Math.trunc((this.targeted / this.total) * 100) : 0;
    },
    width() {
      return this.total < this.sizeLevels[1] ? 3 : 4;
    },
    title() {
      const titleValue = this.targeted === 0 ? this.total : this.targeted;
      return NumberHelper.shortenNumber(titleValue, 1000, 1);
    },
    titleClass() {
      return this.total >= this.sizeLevels[0] ? 'text-h5' : 'text-h6';
    },
  },
  mounted: function () {
    this.isMounted = true;
  },
  methods: {
    clicked() {
      this.$emit('clusterClicked');
    },
  },
});
</script>

<style scoped>
.map-cluster:hover {
  transform: scale(1.1);
}
</style>
