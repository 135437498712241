﻿<template lang="pug">
div
  geo-autocomplete(
    :geolocations='searchGeolocations'
    :loading='searchLoading'
    :label='searchLabel'
    :max-bulk-upload-entries='maxBulkUploadEntries'
    @geolocationSelected='geolocation => geolocationSelected(geolocation)'
    @search="query => search(query)"
    @openBulkUpload="openBulkUpload()")

  v-scroll-y-transition(hide-on-leave)
    div.pt-3.px-2.d-flex.justify-space-between(v-if="value.length >= 10")
      v-btn.see-all-locations(text color="primary" v-if="value.length >= 100" @click="openSeeAllLocations()")  View All {{ value.length }} Locations
      v-btn.clear-all.ml-auto(text color="primary" @click="clearAllGeoTargets()")  Clear All

  v-list#geo-items-list.py-1
    div(v-if="!initializing" v-for="(geoTarget, idx) in value.slice(0, 100)" :key="geoTarget.geolocation.value")
      geo-target-element(
        :geo-target="geoTarget"
        @updateRadius="newRadius => updateRadius(idx, newRadius)"
        @updateInclusion="isIncluded => updateInclusion(idx, isIncluded)"
        @delete="deleteGeoTarget(idx)"
        @select="selectGeoTarget(idx)"
        :active="activeIndex === idx"
        :unit="unit"
        :resolve-geo-json-func="resolveGeoJsonFunc")
      v-divider
    v-skeleton-loader(v-if="initializing" type="list-item-avatar, divider")
    div(v-for="n in loadingGeoTargetsCount")
      v-skeleton-loader.geo-target-placeholder(type="list-item-avatar, divider")

  v-dialog.bulk-upload-dlg(
    v-model="bulkUploadDialogOpen"
    max-width="1200px"
    :fullscreen="$vuetify.breakpoint.smAndDown")
    geo-bulk-upload(
      v-if="bulkUploadDialogOpen"
      :search-func="searchFunc"
      :batch-search-func="batchSearchFunc"
      :batch-options="bulkUploadBatchOptions"
      :resolve-geo-json-func="resolveGeoJsonFunc"
      :postcode-label="postcodeLabel"
      :state-label="stateLabel"
      :textarea-label="searchLabel"
      :unit="unit"
      :max-entries="maxBulkUploadEntries"
      :can-bulk-upload-screen-ids="canBulkUploadScreenIds"
      @cancelBulkUpload="closeBulkUpload()"
      @bulkAdd="geoTargets => bulkAddGeoTargets(geoTargets)")

  v-dialog.all-locations-dlg(
    v-model="allLocationsDialogOpen"
    max-width="900px"
  )
    all-locations(
      v-if="allLocationsDialogOpen"
      @updateRadius="(idx, newRadius) => updateRadius(idx, newRadius)"
      @updateInclusion="(idx, isIncluded) => updateInclusion(idx, isIncluded)"
      @delete="idx => deleteGeoTarget(idx)"
      @select="idx => selectGeoTarget(idx)"
      @clearAll="clearAllGeoTargets()"
      @closeDialog="closeSeeAllLocations()"
      :unit="unit"
      :locations="value"
      :resolve-geo-json-func="resolveGeoJsonFunc"
    )

</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import {
  ActionName,
  allUnits,
  GeolocationType,
  GeoTarget,
  LocationApiPoco,
  Unit,
  BulkUploadBatchingOptions,
  ResolveGeoJsonFunc,
} from '@/types/GeolocationTypes';
import { CancelableRequest } from '@/types/CancelableRequest';

import GeoAutocomplete from '@/components/GeoAutocomplete.vue';
import GeoTargetElement from '@/components/GeoTargetElement.vue';
import GeoBulkUpload from '@/components/GeoBulkUpload.vue';
import AllLocations from '@/components/AllLocations.vue';

import GeoService from '@/services/GeoService';
import RadiusService from '@/services/RadiusService';
import CanceledErrorService from '@/services/CanceledErrorService';

export default defineComponent({
  name: 'AudienceGeoTargets',
  components: {
    GeoBulkUpload,
    GeoTargetElement,
    GeoAutocomplete,
    AllLocations,
  },
  props: {
    initializing: {
      type: Boolean,
    },
    value: {
      type: Array as PropType<GeoTarget[]>,
      required: true,
    },
    activeIndex: {
      type: Number as PropType<number | null>,
      default: null,
    },
    unit: {
      type: String as PropType<Unit>,
      validator: (val: Unit) => allUnits.includes(val),
      default: 'metric',
    },
    searchFunc: {
      type: Function as PropType<
        (query: string, count: number, locationType?: GeolocationType) => CancelableRequest<LocationApiPoco[]>
      >,
      required: true,
    },
    batchSearchFunc: {
      type: Function as PropType<
        (queries: { id: number; query: string }[], locationType: GeolocationType) => Promise<{ [key in number]: LocationApiPoco }>
      >,
      default: null,
    },
    bulkUploadBatchOptions: {
      type: Object as PropType<{
        inventory: BulkUploadBatchingOptions;
        geocoded: BulkUploadBatchingOptions;
        address: BulkUploadBatchingOptions;
      }>,
      default: () => ({
        inventory: {
          batchSize: 100,
          numberOfParallelCalls: 10,
        },
        geocoded: {
          batchSize: 20,
          numberOfParallelCalls: 5,
        },
        address: {
          batchSize: 20,
          numberOfParallelCalls: 5,
        },
      }),
    },
    bulkUploadBatchSize: {
      type: Number,
      default: 100,
    },
    bulkUploadNumberOfParallelBatchCalls: {
      type: Number,
      default: 10,
    },
    resolveGeoJsonFunc: {
      type: Function as PropType<ResolveGeoJsonFunc>,
      required: true,
    },
    postcodeLabel: {
      type: String,
      default: 'Postal Code',
    },
    stateLabel: {
      type: String,
      default: 'Province',
    },
    searchLabel: {
      type: [String, () => undefined],
      default: undefined,
    },
    maxBulkUploadEntries: {
      type: Number,
      validator: (value: number) => value > 0,
      default: 250,
    },
    canBulkUploadScreenIds: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    input: (_value: GeoTarget[]) => true,
    geoTargetSelected: (_value: GeoTarget, _idx: number) => true,
    action: (_name: ActionName, _value: GeoTarget) => true,
    bulkSearchOpened: () => true,
    /* eslint-enable @typescript-eslint/no-unused-vars */
  },
  data() {
    return {
      searchLoading: false,
      searchGeolocations: [] as LocationApiPoco[],
      cancelPendingGeoAutocompleteRequest: null as (() => void) | null,
      bulkUploadDialogOpen: false,
      allLocationsDialogOpen: false,
      loadingGeoTargetsCount: 0,
    };
  },
  computed: {
    currentGeolocationValues() {
      return this.value.map((x) => x.geolocation.value);
    },
  },
  methods: {
    async geolocationSelected(geolocation: LocationApiPoco) {
      if (!this.currentGeolocationValues.includes(geolocation.value)) {
        this.loadingGeoTargetsCount++;
        const defaultRadius = RadiusService.defaultRadiuses(geolocation.type, this.unit);

        const geoJSON =
          geolocation.geography.geoJSON ??
          (GeoService.shouldPreloadGeoJson(geolocation.type)
            ? await this.resolveGeoJsonFunc(geolocation.geography.id, geolocation.geography.geoJSONFileUrl)
            : null);

        const newGeoTarget: GeoTarget = {
          geolocation: {
            ...geolocation,
            geography: {
              latitude: geolocation.geography.latitude,
              longitude: geolocation.geography.longitude,
              geoJSONFileUrl: geolocation.geography.geoJSONFileUrl,
              geoJSON,
            },
          },
          isIncluded: true,
          radius: defaultRadius,
        };
        this.$emit('input', this.value.concat(newGeoTarget));
        this.$emit('action', 'locationAdded', newGeoTarget);
        this.loadingGeoTargetsCount--;
      }
    },
    bulkAddGeoTargets(geoTargets: GeoTarget[]) {
      const newGeoTargets = geoTargets.filter((x) => !this.currentGeolocationValues.includes(x.geolocation.value));
      if (newGeoTargets.length > 0) {
        this.$emit('input', this.value.concat(newGeoTargets));
        newGeoTargets.forEach((newGeoTarget: GeoTarget) => this.$emit('action', 'locationBulkAdded', newGeoTarget));
      }
      this.bulkUploadDialogOpen = false;
    },
    updateRadius(index: number, newRadius: string) {
      const geoTargets = this.deepCopyGeoTargets();
      geoTargets[index].radius = newRadius;
      this.$emit('input', geoTargets);
    },
    updateInclusion(index: number, isIncluded: boolean) {
      const geoTargets = this.deepCopyGeoTargets();
      geoTargets[index].isIncluded = isIncluded;
      this.$emit('input', geoTargets);
      this.$emit('action', 'locationInclusionUpdated', geoTargets[index]);
    },
    deleteGeoTarget(index: number) {
      this.$emit('action', 'locationDeleted', this.value[index]);
      this.$emit('input', this.value.slice(0, index).concat(this.value.slice(index + 1)));
    },
    clearAllGeoTargets() {
      this.$emit('input', []);
    },
    selectGeoTarget(index: number) {
      this.$emit('geoTargetSelected', this.value[index], index);
    },
    async search(query: string) {
      this.searchLoading = true;
      this.cancelPendingGeoAutocompleteRequest?.();

      let searchResults: LocationApiPoco[];
      const coordinates = GeoService.tryParseCoordinates(query);
      if (coordinates) {
        searchResults = [GeoService.generateLocationFromCoordinates(coordinates)];
        this.cancelPendingGeoAutocompleteRequest = null;
        this.searchLoading = false;
      } else {
        try {
          const { response, cancel } = this.searchFunc(query, 7);
          this.cancelPendingGeoAutocompleteRequest = cancel;
          searchResults = await response;
          this.searchLoading = false;
        } catch (e) {
          if (!CanceledErrorService.isCanceledError(e)) {
            this.searchLoading = false;
          }
          searchResults = [];
        }
      }

      this.searchGeolocations = searchResults.filter((x) => !this.currentGeolocationValues.includes(x.value));
    },
    deepCopyGeoTargets() {
      return JSON.parse(JSON.stringify(this.value)) as GeoTarget[];
    },
    openBulkUpload() {
      this.bulkUploadDialogOpen = true;
      this.$emit('bulkSearchOpened');
    },
    closeBulkUpload() {
      this.bulkUploadDialogOpen = false;
    },
    openSeeAllLocations() {
      this.allLocationsDialogOpen = true;
    },
    closeSeeAllLocations() {
      this.allLocationsDialogOpen = false;
    },
  },
});
</script>
