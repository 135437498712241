<template lang="pug">
.map-wrapper(:id="`map-wrapper-${mapId}`")
  .overlay.loading.ml-12.mt-4(v-if='mapLoading' :style="fullscreen && fullscreenMapDrawerOpened ? `left: ${fullscreenMapDrawerWidth}px` : ''")
    v-progress-circular(indeterminate :size='45' color='rgba(0,0,0,0.1)' class='map-loading')

  .overlay.right.mr-5.mt-3.d-flex(style="gap: 12px")
    forecast-impressions(
      v-if="showImpressions"
      :targeted-impressions="impressions.targeted"
      :total-impressions="impressions.total"
      :primary-alignment="'right'"
      :loading="forecastLoading"
      elevated compact
    )

    forecast-inventory-summary(
      v-if='showInventorySummary'
      :button-label="fullscreen && fullscreenMapDrawerOpened ? 'Hide Details' : 'See Details'"
      :loading="forecastLoading"
      :number-of-venues="targetedVenues"
      :number-of-screens="targetedScreens"
      :show-button="showDetailsButton"
      @clickButton="seeDetails()"
    )
  
  .overlay.bottom.mb-3.d-flex(:style="fullscreen && fullscreenMapDrawerOpened ? `left: ${fullscreenMapDrawerWidth}px` : ''")
    v-card.ml-3(v-if='showFullscreenButton || !readonly')
      v-btn.fullscreen-btn(v-if='showFullscreenButton' height='40' small text @click='fullscreenToggle' :title='fullscreen ? "Exit fullscreen" : "Enter fullscreen"')
        v-icon.fullscreen-icon {{ fullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}
      v-btn.drop-pin-btn(v-if='!readonly' height='40' small text @click='dropPinToggle' :title='droppingPin ? "Cancel" : "Add a pin"')
        v-icon.drop-pin-icon(:color="droppingPin ? undefined : 'primary'") {{ droppingPin ? 'mdi-close' : 'mdi-pin' }}

    v-scroll-y-transition
      checkbox-card.ml-3(
        v-if='filterOnMove.isVisible'
        label='Filter as I move the map'
        :value='filterOnMove.value'
        @input='onFilterOnMoveChange'
      )

  v-navigation-drawer.fullscreen-drawer(v-if='fullscreen' v-model='fullscreenMapDrawerOpened' absolute :width='fullscreenMapDrawerWidth')
    .fullscreen-drawer-content(v-if='fullscreenMapDrawerOpened')
      slot(name='fullscreen-drawer-content')
  
  div
    v-slide-x-transition
      v-btn.fullscreen-drawer-close.pr-2(
        v-if='fullscreenMapDrawerOpened'
        @click='seeDetails'
        small color='white' elevation=0
        title='Hide Details'
        height='40'
        style='position: absolute; left: 493px; top: 50%; z-index: 2;'
      )
        v-icon mdi-chevron-double-left
  
    .map(:style="`height: ${fullscreen ? '100vh' : height}`" :id='mapId')

  v-container.popup-container
    venue-popup(
      ref='venuePopup'
      v-show='clickedVenueId'
      :getVenueDetailsFunc='getVenueDetailsFunc'
      :is-compact='compactPopups'
      :showActionButtons='!readonly'
      :venueId='clickedVenueId'
      :venueSource='clickedVenueSource'
      @includeVenue='includeVenue'
      @excludeVenue='excludeVenue'
    )

    geo-target-popup(
      ref='geoTargetPopup'
      v-if='focusedGeoTargetIndex !== -1'
      :can-edit-geo-target-position='!readonly && isFocusedGeoTargetDraggable'
      :can-remove-geo-target='!readonly'
      :geo-target='focusedGeoTargetIndex !== -1 ? geoTargets[focusedGeoTargetIndex] : null'
      :is-compact='compactPopups'
      :unit='unit'
      :resolve-geo-json-func='resolveGeoJsonFunc'
      @expandedPopup='onGeoTargetPopupExpanded'
      @cancel='onGeoTargetPopupCancel'
      @remove='onGeoTargetPopupRemove'
      @update='onGeoTargetPopupUpdate'
    )
</template>

<script lang="ts">
import CheckboxCard from '@/components/CheckboxCard.vue';
import ForecastImpressions from '@/components/ForecastImpressions.vue';
import ForecastInventorySummary from '@/components/ForecastInventorySummary.vue';
import GeoTargetPopup from '@/components/GeoTargetPopup.vue';
import MapCluster from '@/components/MapCluster.vue';
import VenuePopup from '@/components/VenuePopup.vue';
import mapboxConfig from '@/config/mapbox.config';
import GeoService from '@/services/GeoService';
import RadiusService from '@/services/RadiusService';
import { ForecastInventoryResponseApiPoco, MapApiPoco, Position } from '@/types/ForecastTypes';
import { Geolocation, GeoTarget, Unit, allUnits, Venue, ResolveGeoJsonFunc } from '@/types/GeolocationTypes';
import mapBoxGl, {
  GeoJSONSourceRaw,
  Map,
  MapboxOptions,
  LngLatBounds,
  LngLatLike,
  GeoJSONSource,
  Marker,
  MarkerOptions,
} from 'mapbox-gl';
import { FeatureCollection, Geometry, GeoJsonProperties } from 'geojson';
import 'mapbox-gl/dist/mapbox-gl.css';
import Vue, { defineComponent, PropType } from 'vue';
import bbox from '@turf/bbox';
import dropPinCursor from '@/assets/drop-pin-24.png';

export default defineComponent({
  name: 'InventoryMap',
  components: { CheckboxCard, ForecastInventorySummary, ForecastImpressions, GeoTargetPopup, MapCluster, VenuePopup },
  props: {
    boundInitialForecast: {
      type: Boolean,
      default: false,
    },
    compactPopups: {
      type: Boolean,
      default: false,
    },
    defaultLatitude: {
      type: Number,
      default: 49.5,
    },
    defaultLongitude: {
      type: Number,
      default: -94.5,
    },
    defaultZoom: {
      type: Number,
      default: 3,
    },
    filterOnMove: {
      type: Object as PropType<{ isVisible: boolean; value: boolean }>,
      default: () => ({ isVisible: false, value: true }),
    },
    forecastInventory: {
      type: Object as PropType<ForecastInventoryResponseApiPoco> | null,
      default: null,
    },
    forecastInventoryLoading: {
      type: Boolean,
      default: false,
    },
    forecastLoading: {
      type: Boolean,
      default: false,
    },
    geoTargets: {
      type: Array as PropType<GeoTarget[]>,
      default: () => [],
    },
    getVenueDetailsFunc: {
      type: Function as PropType<(venueId: number) => Promise<Venue>>,
      default: () => {
        return Promise.reject<Venue>('Props getVenueDetailsFunc not provided');
      },
    },
    height: {
      type: String,
      default: '100vh',
    },
    highlightedGeoTarget: {
      type: Object as PropType<GeoTarget | null>,
      default: null,
    },
    impressions: {
      type: Object as PropType<{ total: number | null; targeted: number | null }>,
      default: () => ({ total: null, targeted: null }),
    },
    mapBoxToken: {
      type: String,
      required: true,
    },
    mapId: {
      default: 'map',
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    showDetailsButton: {
      type: Boolean,
      default: false,
    },
    showFullscreenButton: {
      type: Boolean,
      default: true,
    },
    showImpressions: {
      type: Boolean,
      default: false,
    },
    showInventorySummary: {
      type: Boolean,
      default: true,
    },
    showVenuePopup: {
      type: Boolean,
      default: false,
    },
    targetedScreens: {
      type: Number,
      default: 0,
    },
    targetedVenues: {
      type: Number,
      default: 0,
    },
    unit: {
      type: String as PropType<Unit>,
      validator: (val: Unit) => allUnits.includes(val),
      default: 'metric',
    },
    zoomOnUpdatedForecast: {
      type: Boolean,
      default: false,
    },
    disableRecentering: {
      type: Boolean,
      default: false,
    },
    resolveGeoJsonFunc: {
      type: Function as PropType<ResolveGeoJsonFunc>,
      required: true,
    },
  },
  emits: {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    seeDetails: () => true,
    hideDetails: () => true,
    filterOnMoveChecked: (_value: boolean) => true,
    mapBoundsUpdated: (_mapApiPoco: MapApiPoco) => true,
    geoTargetAdded: (_value: GeoTarget) => true,
    geoTargetSelected: (_value: GeoTarget, _idx: number) => true,
    geoTargetUnselected: () => true,
    geoTargetUpdated: (_value: GeoTarget, _idx: number) => true,
    geoTargetRemoved: (_value: GeoTarget, _idx: number) => true,
    mapOpenedInFullscreen: () => true,
    pinDropped: () => true,
    /* eslint-enable @typescript-eslint/no-unused-vars */
  },
  data() {
    const defaultParams: MapboxOptions = {
      attributionControl: false,
      center: { lon: this.defaultLongitude, lat: this.defaultLatitude },
      container: this.mapId,
      dragRotate: false,
      logoPosition: 'bottom-right',
      scrollZoom: true,
      style: 'https://s3.amazonaws.com/campsite-assets/mapbox-style/style.json',
      zoom: this.defaultZoom,
    };

    return {
      map: null as Map | null,
      mapInitialized: false,
      defaultParams,
      fullscreen: false,
      fullscreenBrowsersEvents: ['fullscreenchange', 'mozfullscreenchange', 'MSFullscreenChange', 'webkitfullscreenchange'],
      fullscreenMapDrawerWidth: 500,
      fullscreenMapDrawerOpened: false,
      droppingPin: false,
      forecastPreviousBounds: null as LngLatBounds | null,
      forecastInitialBoundingDone: false,
      mapAutoBounded: false,
      mapInitialBounds: undefined as LngLatBounds | undefined,
      mapPadding: { top: 20, bottom: 85, left: 15, right: 30 },
      clustersPadding: 56,
      mapClusterInstances: {} as { [key: string]: Vue },
      clusterClickEmitted: false,
      mapboxMarkers: {} as { [key: string]: Marker },
      focusedShape: null as mapBoxGl.MapboxGeoJSONFeature | null,
      canBoundGeoTargets: true,
      processHighlightedGeoTarget: true,
      isWheelEvent: false,
      isMovingShape: false,
      hoveredShape: null as mapBoxGl.MapboxGeoJSONFeature | null,
      clickedVenueId: null as number | null,
      clickedVenueSource: null as string | null,
      venuePopup: null as mapBoxGl.Popup | null,
      geoTargetPopup: null as mapBoxGl.Popup | null,
      highlightedVenueId: null as string | null,
    };
  },
  computed: {
    mapLoading() {
      return !this.mapInitialized || this.forecastInventoryLoading;
    },
    includedRecentlyAvailableVenues() {
      if (!this.forecastInventory?.positions) return [];

      return this.forecastInventory.positions.filter(
        (p) => p.venue && p.venue.status !== 'RecentlyUnavailable' && p.target.size === 1
      );
    },
    excludedRecentlyAvailableVenues() {
      if (!this.forecastInventory?.positions) return [];

      return this.forecastInventory.positions.filter(
        (p) => p.venue && p.venue.status !== 'RecentlyUnavailable' && p.target.size === 0
      );
    },
    includedRecentlyUnavailableVenues() {
      if (!this.forecastInventory?.positions) return [];

      return this.forecastInventory.positions.filter(
        (p) => p.venue && p.venue.status === 'RecentlyUnavailable' && p.target.size === 1
      );
    },
    excludedRecentlyUnavailableVenues() {
      if (!this.forecastInventory?.positions) return [];

      return this.forecastInventory.positions.filter(
        (p) => p.venue && p.venue.status === 'RecentlyUnavailable' && p.target.size === 0
      );
    },
    selectedVenueGeoTargetIndex() {
      if (this.clickedVenueId) {
        const venueIdStr = this.clickedVenueId.toString();
        return this.geoTargets.findIndex(
          (geoTarget) => geoTarget.geolocation.type === 'venue' && geoTarget.geolocation.value === venueIdStr
        );
      } else {
        return -1;
      }
    },
    popupOptions() {
      const options: mapBoxGl.PopupOptions = {
        className: 'inventory-map-popup',
        closeButton: false,
        anchor: 'top',
        maxWidth: this.compactPopups ? '300px' : '400px',
        focusAfterOpen: false,
      };

      return options;
    },
    focusedGeoTargetIndex() {
      const properties = this.focusedShape?.properties;
      if (!properties) return -1;

      const geolocation: Geolocation = JSON.parse(properties.geolocation);
      return this.geoTargets.findIndex((g) => this.matchesGeoTarget(g, geolocation, properties.isIncluded, properties.radius));
    },
    isFocusedGeoTargetDraggable() {
      return this.focusedShape?.properties?.isDraggable;
    },
  },
  watch: {
    height() {
      this.$nextTick(() => {
        this.map?.resize();
      });
    },
    defaultLatitude(newVal) {
      this.defaultParams.center = { lat: newVal, lon: this.defaultLongitude };
    },
    defaultLongitude(newVal) {
      this.defaultParams.center = { lat: this.defaultLatitude, lon: newVal };
    },
    defaultZoom(newVal) {
      this.defaultParams.zoom = newVal;
    },
    forecastInventory() {
      if (!this.mapInitialized || !this.forecastInventory?.positions) return;

      if (this.boundInitialForecast && !this.forecastInitialBoundingDone) {
        this.boundForecast();

        this.defaultParams.center = this.map?.getCenter();
        this.defaultParams.zoom = this.map?.getZoom();

        this.forecastInitialBoundingDone = true;
      } else if (this.zoomOnUpdatedForecast) {
        this.boundForecast();
      }

      this.updateClusterMarkers();
      this.updateVenueMarkers();

      if (
        this.showVenuePopup &&
        this.highlightedGeoTarget &&
        this.highlightedGeoTarget.geolocation.type === 'venue' &&
        this.highlightedGeoTarget.radius == 'Venue Only' &&
        this.highlightedVenueId === this.highlightedGeoTarget.geolocation.value
      ) {
        const clickedVenuePosition = this.forecastInventory.positions.find(
          (p) => p.venue && p.venue.id === this.highlightedGeoTarget?.geolocation.value
        );
        if (clickedVenuePosition) {
          const sourcePrefix = this.highlightedGeoTarget.isIncluded ? 'included' : 'excluded';
          this.clickedVenueSource = sourcePrefix + clickedVenuePosition.venue?.status + 'Venues';

          this.clickedVenueId = parseInt(this.highlightedGeoTarget?.geolocation.value);

          const clickedVenueLngLat = new mapBoxGl.LngLat(
            this.highlightedGeoTarget.geolocation.geography.longitude,
            this.highlightedGeoTarget.geolocation.geography.latitude
          );

          this.openVenuePopup(clickedVenueLngLat);
          this.highlightedVenueId = null;
        }
      }
    },
    fullscreenMapDrawerOpened(newVal) {
      this.shiftMapCenter(newVal);
      this.shiftMapControls(newVal);
    },
    geoTargets(newVal, oldVal) {
      if (this.isAddingExcludedVenue(newVal, oldVal)) {
        this.mapBoundsUpdated();
      } else {
        this.boundGeoTargets();
      }
    },
    highlightedGeoTarget() {
      if (this.processHighlightedGeoTarget) {
        if (this.highlightedGeoTarget) {
          this.handleHighlightedGeoTarget(this.highlightedGeoTarget, false);
        } else {
          this.closeVenuePopup();
          this.unfocusSelectedShape();
        }
      }
    },
  },
  mounted() {
    this.initializeMap();
  },
  methods: {
    initializeMap() {
      if (this.mapBoxToken) {
        mapBoxGl.accessToken = this.mapBoxToken;
        this.map = new Map(this.defaultParams);
        this.map.on('load', this.mapLoaded);
      }
    },
    seeDetails() {
      if (this.fullscreen && this.fullscreenMapDrawerOpened) this.$emit('hideDetails');
      else this.$emit('seeDetails');

      if (this.fullscreen) {
        this.fullscreenMapDrawerOpened = !this.fullscreenMapDrawerOpened;
      }
    },
    fullscreenToggle(event: Event) {
      if (!this.fullscreenBrowsersEvents.includes(event.type)) {
        if (this.fullscreen) {
          document.exitFullscreen();
        } else {
          document.querySelector('#map-wrapper-' + this.mapId)?.requestFullscreen();
          this.$emit('mapOpenedInFullscreen');
        }

        this.fullscreen = !this.fullscreen;
      } else {
        // capture closing fullscreen with keyboard (ESC or F11)
        if (this.fullscreen && !document.fullscreenElement) {
          this.fullscreen = !this.fullscreen;
        }
      }

      if (!this.fullscreen) this.fullscreenMapDrawerOpened = false;
    },
    mapLoaded() {
      this.addImages();
      this.addSources();
      this.addLayers();
      this.addControls();
      this.addListeners();
      this.map?.resize();

      this.mapInitialized = true;

      if (this.geoTargets.length) {
        this.forecastInitialBoundingDone = true;
        this.boundGeoTargets();
      } else {
        this.mapBoundsUpdated();
      }
    },
    addListeners() {
      if (this.showFullscreenButton) {
        document.addEventListener('fullscreenchange', this.fullscreenToggle);
        document.addEventListener('mozfullscreenchange', this.fullscreenToggle);
        document.addEventListener('MSFullscreenChange', this.fullscreenToggle);
        document.addEventListener('webkitfullscreenchange', this.fullscreenToggle);
      }

      this.map?.on('moveend', () => {
        this.mapBoundsUpdated();
      });

      this.map?.on('mousemove', (event) => {
        if (this.droppingPin) {
          this.updateDropPinRadius(event.lngLat);
        } else if (!this.isMovingShape) {
          this.setCursorFromMapPosition(event.point);
          this.setHoverEffect(event.point);
        }
      });

      this.map?.on('wheel', () => {
        this.isWheelEvent = true;
      });

      this.map?.on('zoomstart', (event: mapBoxGl.EventData) => {
        if (this.isWheelEvent || event.originalEvent) {
          this.closeVenuePopup();

          if (this.focusedShape) this.setCursor('inherit');

          this.isMovingShape = false;
          this.unfocusSelectedShape();
          this.emitFocusedGeoTargetAsSelected();
        }
      });

      this.map?.on('zoomend', () => {
        this.isWheelEvent = false;

        if (this.droppingPin) {
          const pinProperties = this.map?.querySourceFeatures('dropPin')[0]?.properties;
          if (pinProperties) this.updateDropPinRadius(new mapBoxGl.LngLat(pinProperties.lng, pinProperties.lat));
        }
      });

      this.map?.on('click', (event) => {
        if (this.isMovingShape) {
          this.setCursor('inherit');
          this.isMovingShape = false;
        } else if (this.droppingPin) {
          this.addDroppedPin(event.lngLat);
        } else {
          if (!this.clusterClickEmitted) {
            this.handleClickEvent(event);
          } else {
            this.clusterClickEmitted = false;
          }
        }
      });

      this.map?.on('mousedown', 'focusedShapeFill', (event) => {
        if (!this.readonly && this.isFocusedGeoTargetDraggable) {
          event.preventDefault();

          this.closeGeoTargetPopup();
          this.map?.on('mousemove', this.moveFocusedShape);

          this.map?.once('mouseup', (event) => {
            this.map?.off('mousemove', this.moveFocusedShape);
            if (this.isMovingShape) {
              this.isMovingShape = false;
              this.updateFocusedGeoTargetPosition(event.lngLat);

              setTimeout(() => {
                this.setCursor('pointer');
              }, 0);
            }
          });
        }
      });
    },
    addImages() {
      this.map?.addImage('excludedRecentlyAvailableVenue', this.generateIcon('recentlyAvailable', 'excluded'));
      this.map?.addImage('includedRecentlyAvailableVenue', this.generateIcon('recentlyAvailable', 'included'));
      this.map?.addImage('excludedRecentlyUnavailableVenue', this.generateIcon('recentlyUnavailable', 'excluded'));
      this.map?.addImage('includedRecentlyUnavailableVenue', this.generateIcon('recentlyUnavailable', 'included'));
    },
    generateIcon(status: 'recentlyAvailable' | 'recentlyUnavailable', inclusion: 'included' | 'excluded') {
      const grey = '#a8a7a7';
      const colors = {
        included: this.$vuetify.theme.themes.light.primary as string,
        excluded: grey,
      };
      const paths = {
        recentlyAvailable: mapboxConfig.PinPaths.DEFAULT_PIN,
        recentlyUnavailable: mapboxConfig.PinPaths.ALERT_PIN,
      };

      const canvasVenuePin = document.createElement('canvas');

      const pinSize = 48;
      canvasVenuePin.width = pinSize;
      canvasVenuePin.height = pinSize;
      canvasVenuePin.style.width = '24px';
      canvasVenuePin.style.height = '24px';

      const ctx = canvasVenuePin.getContext('2d');
      if (!ctx) return new ImageData(0, 0);

      ctx?.scale(2, 2);
      ctx.fillStyle = colors[inclusion];
      const path = new Path2D(paths[status]);
      ctx?.fill(path);

      return ctx.getImageData(0, 0, pinSize, pinSize);
    },
    addSources() {
      const sourceObj: GeoJSONSourceRaw = {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      };

      const { sources } = mapboxConfig;
      sources.map((source) => {
        this.map?.addSource(source, sourceObj);
      });
    },
    addLayers() {
      const themeColors = this.$vuetify.theme.themes.light;
      const layers = mapboxConfig.getLayers(themeColors);
      for (const layerId in layers) {
        this.map?.addLayer(layers[layerId]);
      }
    },
    addControls() {
      var nav = new mapBoxGl.NavigationControl({
        showCompass: false,
      });
      this.map?.addControl(nav, 'top-left');
    },
    shiftMapCenter(fullscreenMapDrawerOpened: boolean) {
      if (this.map) {
        const currentCenterLngLat = this.map.getCenter();
        const currentCenterPixels = this.map.project(currentCenterLngLat);

        const shiftedCenterXPixel = fullscreenMapDrawerOpened
          ? currentCenterPixels.x - this.fullscreenMapDrawerWidth / 2
          : currentCenterPixels.x + this.fullscreenMapDrawerWidth / 2;

        const shiftedCenterLngLat = this.map.unproject(new mapBoxGl.Point(shiftedCenterXPixel, currentCenterPixels.y));

        this.map.jumpTo({ center: shiftedCenterLngLat });
      }
    },
    shiftMapControls(fullscreenMapDrawerOpened: boolean) {
      if (this.map) {
        const mapCtrls = document.querySelector(`#map-wrapper-${this.mapId} .mapboxgl-ctrl-top-left`);
        const left = fullscreenMapDrawerOpened ? '500' : '0';
        mapCtrls?.setAttribute('style', `left: ${left}px`);
      }
    },
    mapBoundsUpdated() {
      var bounds = this.map?.getBounds();
      if (bounds) {
        const boundsChanged = GeoService.mapBoundsHaveChanged(this.forecastPreviousBounds, bounds);
        if (boundsChanged) {
          this.forecastPreviousBounds = bounds;
          this.$emit('mapBoundsUpdated', this.bundleMapSettings(bounds));
        }
      }
    },
    bundleMapSettings(mapBounds: LngLatBounds): MapApiPoco {
      const ne = mapBounds._ne;
      const sw = mapBounds._sw;

      const mapContainer = this.map?.getContainer();
      const zoom = this.map?.getZoom();

      return {
        dimensionInPixels: {
          width: mapContainer?.clientWidth ?? 0,
          height: mapContainer?.clientHeight ?? 0,
        },
        min: {
          latitude: sw.lat,
          longitude: sw.lng < -180 ? -180 : sw.lng > 180 ? 180 : sw.lng,
        },
        max: {
          latitude: ne.lat,
          longitude: ne.lng < -180 ? -180 : ne.lng > 180 ? 180 : ne.lng,
        },
        zoom: zoom ?? this.defaultZoom,
      };
    },
    boundForecast() {
      var forecastPositions = this.forecastInventory.positions.filter((p) => p.target.size > 0);
      if (!forecastPositions.length) {
        return;
      }

      this.mapAutoBounded = true;

      const clustersBounds = new mapBoxGl.LngLatBounds();

      forecastPositions.forEach((p) => {
        const pLngLat = new mapBoxGl.LngLat(p.longitude, p.latitude);
        const pBounds = pLngLat.toBounds(p.radiusInKM * 1000);
        clustersBounds.extend(pBounds);
      });

      const newCameraTransform = this.map?.cameraForBounds(clustersBounds, {
        padding: this.clustersPadding,
      });

      if (newCameraTransform) {
        this.map?.jumpTo(newCameraTransform);
      }

      this.mapAutoBounded = false;
    },
    updateVenueMarkers() {
      const includedRecentlyAvailableVenuesData = this.getFeatureCollection(this.includedRecentlyAvailableVenues);
      (this.map?.getSource('includedRecentlyAvailableVenues') as GeoJSONSource).setData(includedRecentlyAvailableVenuesData);

      const excludedRecentlyAvailableVenuesData = this.getFeatureCollection(this.excludedRecentlyAvailableVenues);
      (this.map?.getSource('excludedRecentlyAvailableVenues') as GeoJSONSource).setData(excludedRecentlyAvailableVenuesData);

      const includedRecentlyUnavailableVenuesData = this.getFeatureCollection(this.includedRecentlyUnavailableVenues);
      (this.map?.getSource('includedRecentlyUnavailableVenues') as GeoJSONSource).setData(includedRecentlyUnavailableVenuesData);

      const excludedRecentlyUnavailableVenuesData = this.getFeatureCollection(this.excludedRecentlyUnavailableVenues);
      (this.map?.getSource('excludedRecentlyUnavailableVenues') as GeoJSONSource).setData(excludedRecentlyUnavailableVenuesData);
    },
    getFeatureCollection(features: Array<Position> = []): FeatureCollection {
      return {
        type: 'FeatureCollection',
        features: features.map((f) => ({
          type: 'Feature',
          properties: {
            id: f.venue ? f.venue.id : f.id,
            total: f.size,
            targeted: f.target.size,
          },
          geometry: {
            type: 'Point',
            coordinates: [f.longitude, f.latitude],
          },
        })),
      };
    },
    boundGeoTargets(highlightedGeoTarget: GeoTarget | null = null) {
      if (!this.mapInitialized || !this.canBoundGeoTargets) {
        return;
      }

      const geoTargets = highlightedGeoTarget ? [highlightedGeoTarget] : this.geoTargets;
      const num = geoTargets.length;

      if (!num && !this.disableRecentering) {
        this.map?.flyTo({
          center: this.defaultParams.center,
          duration: 1200,
          padding: this.mapPadding,
          zoom: this.defaultParams.zoom,
        });
      }

      if (num === 1 && this.isVenueOnlyIncluded(geoTargets[0]) && !this.disableRecentering) {
        this.jumpToVenue(geoTargets[0]);
      } else {
        const geoTargetsFiltered = highlightedGeoTarget
          ? geoTargets
          : geoTargets.filter((geoTarget) => (geoTarget.geolocation.type === 'venue' ? geoTarget.isIncluded : true));
        if (geoTargetsFiltered.length && !this.disableRecentering) {
          const b = new mapBoxGl.LngLatBounds();

          geoTargetsFiltered.map((geoTarget) => {
            if (this.isRadius(geoTarget)) {
              const radiusInKM = RadiusService.convertRadiusLabelToKm(geoTarget.radius as string);

              const pLngLat = new mapBoxGl.LngLat(
                geoTarget.geolocation.geography.longitude,
                geoTarget.geolocation.geography.latitude
              );
              const pBounds = pLngLat.toBounds((radiusInKM as number) * 1000);
              b.extend(pBounds);
            } else if (this.isPolygon(geoTarget) && geoTarget.geolocation.geography.geoJSON) {
              const geoJSON = JSON.parse(geoTarget.geolocation.geography.geoJSON);
              const polygonBbox = bbox(geoJSON);

              const polygonSWLngLat = new mapBoxGl.LngLat(polygonBbox[0], polygonBbox[1]);
              const polygonNELngLat = new mapBoxGl.LngLat(polygonBbox[2], polygonBbox[3]);

              b.extend(polygonSWLngLat);
              b.extend(polygonNELngLat);
            } else {
              b.extend([geoTarget.geolocation.geography.longitude, geoTarget.geolocation.geography.latitude]);
            }
          });

          const optionsCamera = {
            padding: this.mapPadding,
          };

          const newCameraTransform = this.map?.cameraForBounds(b, optionsCamera);
          if (newCameraTransform) {
            this.map?.jumpTo(newCameraTransform);
          }
        } else {
          this.mapBoundsUpdated();
        }
      }

      this.updateGeoFeatures();
    },
    jumpToVenue(venue: GeoTarget) {
      this.map?.jumpTo({
        center: [venue.geolocation.geography.longitude, venue.geolocation.geography.latitude],
        padding: this.mapPadding,
        zoom: 16,
      });
    },
    isVenueOnly(geoTarget: GeoTarget) {
      return geoTarget.geolocation.type === 'venue' && geoTarget.radius === 'Venue Only';
    },
    isVenueOnlyIncluded(geoTarget: GeoTarget) {
      return this.isVenueOnly(geoTarget) && geoTarget.isIncluded;
    },
    isPolygon(geoTarget: GeoTarget) {
      const type = geoTarget.geolocation.type;
      const radius = geoTarget.radius;
      return (
        !!geoTarget.geolocation.geography.geoJSON &&
        (type === 'country' ||
          type === 'state' ||
          type === 'postal_code' ||
          (type === 'city' && (!radius || radius === 'City Only')))
      );
    },
    isRadius(geoTarget: GeoTarget) {
      const radius = geoTarget.radius;
      if (!radius) {
        return false;
      }

      const type = geoTarget.geolocation.type;
      const radiusInKM = RadiusService.convertRadiusLabelToKm(radius);
      return !!radiusInKM && radiusInKM > 0 && ['address', 'city', 'venue'].includes(type);
    },
    forceRenderOnHighlightedGeotarget(geoTargets: GeoTarget[]) {
      const focusedGeoTarget = geoTargets.find(
        (geoTarget) =>
          this.highlightedGeoTarget &&
          this.matchesGeoTarget(this.highlightedGeoTarget, geoTarget.geolocation, geoTarget.isIncluded, geoTarget.radius)
      );
      if (focusedGeoTarget) {
        if (this.highlightedGeoTarget?.geolocation.geography.geoJSON)
          focusedGeoTarget.geolocation.geography.geoJSON ??= this.highlightedGeoTarget?.geolocation.geography.geoJSON;
        focusedGeoTarget.isUnrendered = false;
      }
    },
    updateGeoFeatures() {
      const noneSelected = this.geoTargets.length === 0;

      let geoTargetsToRender = this.geoTargets;
      if (this.highlightedGeoTarget) {
        geoTargetsToRender = JSON.parse(JSON.stringify(this.geoTargets));
        this.forceRenderOnHighlightedGeotarget(geoTargetsToRender);
      }
      geoTargetsToRender = geoTargetsToRender.filter((g) => !g.isUnrendered);

      const polygons = geoTargetsToRender.filter(this.isPolygon).map((geoTarget, i) => {
        const properties: GeoJsonProperties = Object.assign({}, geoTarget, { isDraggable: false });
        return {
          id: i,
          properties,
          geometry: GeoService.getGeometryFromGeoJsonString(geoTarget.geolocation.geography.geoJSON as string),
          type: 'Feature',
        };
      });

      const polygonsCollection = {
        type: 'FeatureCollection',
        features: noneSelected ? [] : polygons,
      };
      (this.map?.getSource('polygons') as GeoJSONSource).setData(
        polygonsCollection as FeatureCollection<Geometry, GeoJsonProperties>
      );

      const radiuses = geoTargetsToRender.filter(this.isRadius).map((geoTarget, i) => {
        const radiusInKm = RadiusService.convertRadiusLabelToKm(geoTarget.radius as string);
        const properties: GeoJsonProperties = Object.assign({}, geoTarget, { isDraggable: !!radiusInKm, radiusInKm });
        return {
          id: i,
          properties,
          geometry: GeoService.generateGeoJsonCircle(
            geoTarget.geolocation.geography.longitude,
            geoTarget.geolocation.geography.latitude,
            radiusInKm as number
          ),
        };
      });

      const radiusesCollection = {
        type: 'FeatureCollection',
        features: noneSelected ? [] : radiuses,
      };
      (this.map?.getSource('radiuses') as GeoJSONSource).setData(
        radiusesCollection as FeatureCollection<Geometry, GeoJsonProperties>
      );
    },
    updateClusterMarkers() {
      const clusters = this.forecastInventory.positions.filter((p) => !p.venue);
      clusters.sort(this.sortByTotal);

      const visibleClustersIds = clusters.map((cluster) => {
        const data = {
          id: cluster.id,
          targeted: cluster.target.size,
          total: cluster.size,
        };
        const coords: LngLatLike = [cluster.longitude, cluster.latitude];

        const existingMapClusterInstance = this.mapClusterInstances[cluster.id];
        if (existingMapClusterInstance) {
          existingMapClusterInstance.$data.targeted = data.targeted;
          existingMapClusterInstance.$data.total = data.total;
        } else {
          const MapClusterComponent = Vue.component('MapCluster', MapCluster);
          const mapClusterInstance = new MapClusterComponent({
            parent: this as unknown as Vue,
            data() {
              return data;
            },
          });

          mapClusterInstance.$mount();
          mapClusterInstance.$on('clusterClicked', () => this.clusterClicked(coords));

          this.$set(this.mapClusterInstances, data.id, mapClusterInstance);

          const mapboxMarker = new mapBoxGl.Marker(mapClusterInstance.$el as MarkerOptions);
          mapboxMarker.setLngLat(coords);
          mapboxMarker.addTo(this.map as Map);

          this.mapboxMarkers[data.id] = mapboxMarker;
        }

        return data.id;
      });

      this.cleanupClusters(visibleClustersIds);
    },
    cleanupClusters(visibleClustersIds: string[]) {
      this.cleanupClusterInstances(visibleClustersIds);
      this.cleanupClusterMarkers(visibleClustersIds);
    },
    cleanupClusterInstances(visibleClustersIds: string[]) {
      for (let clusterId in this.mapClusterInstances) {
        if (!visibleClustersIds.includes(clusterId)) {
          this.mapClusterInstances[clusterId].$destroy();
          delete this.mapClusterInstances[clusterId];
        }
      }
    },
    cleanupClusterMarkers(visibleClustersIds: string[]) {
      for (let clusterId in this.mapboxMarkers) {
        if (!visibleClustersIds.includes(clusterId)) {
          this.mapboxMarkers[clusterId].remove();
          delete this.mapboxMarkers[clusterId];
        }
      }
    },
    sortByTotal(clusterA: Position, clusterB: Position) {
      if (clusterA.size > clusterB.size) {
        return 1;
      }
      if (clusterA.size < clusterB.size) {
        return -1;
      }
      return 0;
    },
    clusterClicked(coordinates: LngLatLike) {
      this.clusterClickEmitted = true;

      const zoom = this.map?.getZoom();
      if (zoom) {
        this.map?.flyTo({
          center: coordinates,
          padding: this.mapPadding,
          zoom: zoom < 10 ? zoom + 3 : zoom + 1,
          duration: 250,
        });
      }
    },
    dropPinToggle() {
      this.droppingPin = !this.droppingPin;
      this.setCursor(this.droppingPin ? `url('${dropPinCursor}') 12 12, auto` : 'move');

      if (this.droppingPin) {
        this.unfocusSelectedShape();
        this.emitFocusedGeoTargetAsSelected();
      } else {
        (this.map?.getSource('dropPin') as GeoJSONSource).setData({ type: 'FeatureCollection', features: [] });
      }
    },
    updateDropPinRadius(lngLat: mapBoxGl.LngLat) {
      const layers = mapboxConfig.getDropPinLayers(this.$vuetify.theme.themes.light);
      for (const layerId in layers) {
        if (!this.map?.getLayer(layerId)) this.map?.addLayer(layers[layerId]);
      }

      const featureCollection: FeatureCollection = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              lng: lngLat.lng,
              lat: lngLat.lat,
            },
            geometry: GeoService.generateGeoJsonCircle(
              lngLat.lng,
              lngLat.lat,
              RadiusService.convertRadiusLabelToKm(this.calculateRadiusFromZoomLevel()) as number
            ) as Geometry,
          },
        ],
      };
      (this.map?.getSource('dropPin') as GeoJSONSource).setData(featureCollection);
    },
    calculateRadiusFromZoomLevel() {
      const minZoom = 7;
      const maxZoom = 16;
      const zoom = this.map?.getZoom() ?? minZoom;

      return RadiusService.interpolateRadiusFromMapZoom(zoom, minZoom, maxZoom, this.unit);
    },
    addDroppedPin(lngLat: mapBoxGl.LngLat) {
      const radius = this.calculateRadiusFromZoomLevel();

      const geoTarget: GeoTarget = {
        geolocation: {
          geography: {
            geoJSON: null,
            geoJSONFileUrl: null,
            latitude: lngLat.lat,
            longitude: lngLat.lng,
          },
          label: `${lngLat.lat}, ${lngLat.lng}`,
          type: 'address',
          value: `${lngLat.lat}, ${lngLat.lng}`,
        },
        isIncluded: true,
        radius: radius,
      };

      this.canBoundGeoTargets = false;
      this.$emit('geoTargetAdded', geoTarget);
      this.$emit('pinDropped');
      this.dropPinToggle();
      setTimeout(() => {
        this.canBoundGeoTargets = true;
        this.handleHighlightedGeoTarget(geoTarget);
      }, 0);
    },
    handleClickEvent(event: mapBoxGl.MapMouseEvent) {
      this.closeVenuePopup();
      this.unfocusSelectedShape();

      const venueFeature = this.detectVenueFromMapPosition(event.point);
      if (venueFeature) {
        this.focusSelectedVenue(venueFeature, event.lngLat);
      } else {
        this.setCursorFromMapPosition(event.point, true);
        this.highlightShapeAtPosition(event.point);
        this.openGeoTargetPopup(event.lngLat);
        this.emitFocusedGeoTargetAsSelected();
      }
    },
    focusSelectedVenue(venueFeature: mapBoxGl.MapboxGeoJSONFeature, lngLat: mapBoxGl.LngLat) {
      this.clickedVenueId = parseInt(venueFeature.properties?.id);
      this.clickedVenueSource = venueFeature.source;

      if (this.selectedVenueGeoTargetIndex > -1) {
        this.processHighlightedGeoTarget = false;

        this.$emit('geoTargetSelected', this.geoTargets[this.selectedVenueGeoTargetIndex], this.selectedVenueGeoTargetIndex);

        setTimeout(() => {
          this.processHighlightedGeoTarget = true;
        }, 0);
      }

      this.openVenuePopup(lngLat);
    },
    highlightShapeAtPosition(point: mapBoxGl.Point, geoTarget?: GeoTarget) {
      this.matchShapeFromMapPosition(point, geoTarget);
      this.focusSelectedShape();
    },
    unfocusSelectedShape() {
      if (this.focusedShape) {
        const filter = this.map?.getFilter(this.focusedShape.layer.id);
        if (filter) this.map?.setFilter(this.focusedShape.layer.id, filter[1]);
        this.focusedShape = null;

        (this.map?.getSource('focusedShape') as GeoJSONSource).setData({
          type: 'FeatureCollection',
          features: [],
        });

        this.closeGeoTargetPopup();
      }
    },
    focusSelectedShape() {
      if (this.focusedShape) {
        const inclusionFilter = this.map?.getFilter(this.focusedShape.layer.id);
        const newFilter = ['all', inclusionFilter, ['!=', ['id'], this.focusedShape.id]];
        this.map?.setFilter(this.focusedShape.layer.id, newFilter);

        const properties = this.focusedShape.properties;
        const geolocation: Geolocation = JSON.parse(properties?.geolocation);

        const { longitude, latitude, geoJSON } = geolocation.geography;
        const geometry: Geometry =
          properties?.radiusInKm && typeof properties.radiusInKm === 'number'
            ? (GeoService.generateGeoJsonCircle(longitude, latitude, properties.radiusInKm) as Geometry)
            : GeoService.getGeometryFromGeoJsonString(geoJSON as string);

        const newCollection: FeatureCollection = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: geometry,
              properties: properties,
            },
          ],
        };

        (this.map?.getSource('focusedShape') as GeoJSONSource).setData(newCollection);
      }
    },
    matchShapeFromMapPosition(position: mapBoxGl.Point, geoTarget?: GeoTarget) {
      const features = this.map?.queryRenderedFeatures(position, {
        layers: mapboxConfig.focusableLayerIds,
      });

      if (!features || features.length === 0) return;

      if (geoTarget && features.length > 1) {
        const feature = this.findFeatureMatchingGeoTarget(features, geoTarget);
        if (feature) this.focusedShape = feature;
      } else {
        this.focusedShape = features[0];
      }
    },
    findFeatureMatchingGeoTarget(features: mapBoxGl.MapboxGeoJSONFeature[], geoTarget: GeoTarget) {
      return features.find((f) => {
        const featureGeolocation: Geolocation = JSON.parse(f.properties?.geolocation);
        return this.matchesGeoTarget(geoTarget, featureGeolocation, f.properties?.isIncluded, f.properties?.radius);
      });
    },
    matchesGeoTarget(geoTarget: GeoTarget, geolocation: Geolocation, isIncluded: boolean, radius?: string) {
      return (
        geoTarget.geolocation.value === geolocation.value &&
        geoTarget.geolocation.geography.latitude === geolocation.geography.latitude &&
        geoTarget.geolocation.geography.longitude === geolocation.geography.longitude &&
        geoTarget.isIncluded === isIncluded &&
        geoTarget.radius === radius
      );
    },
    emitFocusedGeoTargetAsSelected() {
      this.processHighlightedGeoTarget = false;

      if (this.focusedGeoTargetIndex !== -1)
        this.$emit('geoTargetSelected', this.geoTargets[this.focusedGeoTargetIndex], this.focusedGeoTargetIndex);
      else this.$emit('geoTargetUnselected');

      setTimeout(() => {
        this.processHighlightedGeoTarget = true;
      }, 0);
    },
    generateDelay() {
      if (!this.geoTargets || this.geoTargets.length < 80) return 300;
      if (this.geoTargets.length < 160) return 2.5 * this.geoTargets.length + 100;
      return 500;
    },
    handleHighlightedGeoTarget(geoTarget: GeoTarget, emitHighlightedShape = true) {
      this.closeVenuePopup();
      this.unfocusSelectedShape();

      this.boundGeoTargets(geoTarget);

      if (geoTarget.geolocation.type === 'venue' && geoTarget.radius === 'Venue Only') {
        this.highlightedVenueId = geoTarget.geolocation.value;
        return;
      }

      const lngLat = new mapBoxGl.LngLat(geoTarget.geolocation.geography.longitude, geoTarget.geolocation.geography.latitude);
      const point = this.map?.project(lngLat);
      if (point) {
        setTimeout(() => {
          this.highlightShapeAtPosition(point, geoTarget);
          this.openGeoTargetPopup(lngLat);
          if (emitHighlightedShape) this.emitFocusedGeoTargetAsSelected();
        }, this.generateDelay());
      }
    },
    moveFocusedShape(event: mapBoxGl.MapMouseEvent) {
      if (!this.focusedShape) return;

      this.isMovingShape = true;

      const newCollection: FeatureCollection = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: GeoService.generateGeoJsonCircle(
              event.lngLat.lng,
              event.lngLat.lat,
              this.focusedShape.properties?.radiusInKm
            ) as Geometry,
            properties: this.focusedShape.properties,
          },
        ],
      };

      (this.map?.getSource('focusedShape') as GeoJSONSource).setData(newCollection);
    },
    updateFocusedGeoTargetPosition(newPosition: mapBoxGl.LngLat) {
      if (this.focusedGeoTargetIndex !== -1) {
        const geoTarget = this.geoTargets[this.focusedGeoTargetIndex];
        const updatedGeoTarget: GeoTarget = {
          geolocation: {
            geography: {
              geoJSON: geoTarget.geolocation.geography.geoJSON,
              geoJSONFileUrl: geoTarget.geolocation.geography.geoJSONFileUrl,
              latitude: newPosition.lat,
              longitude: newPosition.lng,
            },
            label: geoTarget.geolocation.label,
            type: 'address',
            value: `${newPosition.lat}, ${newPosition.lng}`,
          },
          isIncluded: geoTarget.isIncluded,
          radius: geoTarget.radius,
        };

        this.$emit('geoTargetUpdated', updatedGeoTarget, this.focusedGeoTargetIndex);
        this.unfocusSelectedShape();
        this.emitFocusedGeoTargetAsSelected();
      }
    },
    setCursorFromMapPosition(point: mapBoxGl.Point, isOnClick = false) {
      const clickableLayers = [...(this.showVenuePopup ? mapboxConfig.venueLayerIds : []), ...mapboxConfig.focusableLayerIds];

      const clickableFeatures = this.map?.queryRenderedFeatures(point, {
        layers: clickableLayers,
      });
      const focusedFeatures = this.map?.queryRenderedFeatures(point, {
        layers: ['focusedShapeFill', 'focusedShapeStroke'],
      });

      const cursor = isOnClick
        ? this.getCursorOnClick(clickableFeatures, focusedFeatures)
        : this.getCursorOnMove(clickableFeatures, focusedFeatures);

      this.setCursor(cursor);
    },
    getCursorOnMove(clickableFeatures?: mapBoxGl.MapboxGeoJSONFeature[], focusedFeatures?: mapBoxGl.MapboxGeoJSONFeature[]) {
      if (clickableFeatures?.length) return 'pointer';
      if (focusedFeatures?.length) return focusedFeatures[0]?.properties?.isDraggable && !this.readonly ? 'move' : 'pointer';
      return '';
    },
    getCursorOnClick(clickableFeatures?: mapBoxGl.MapboxGeoJSONFeature[], focusedFeatures?: mapBoxGl.MapboxGeoJSONFeature[]) {
      if (clickableFeatures?.length) return clickableFeatures[0]?.properties?.isDraggable && !this.readonly ? 'move' : 'pointer';
      if (focusedFeatures?.length) return 'pointer';
      return '';
    },
    setCursor(cursor: string) {
      if (this.map) this.map.getCanvas().style.cursor = cursor;
    },
    setHoverEffect(point: mapBoxGl.Point) {
      const features = this.map?.queryRenderedFeatures(point, {
        layers: mapboxConfig.focusableLayerIds,
      });

      if (this.hoveredShape) {
        this.map?.setFeatureState({ source: this.hoveredShape.source, id: this.hoveredShape.id }, { hover: false });
      }

      if (features && features.length > 0) {
        this.hoveredShape = features[0];
        this.map?.setFeatureState({ source: this.hoveredShape.source, id: this.hoveredShape.id }, { hover: true });
      } else {
        this.hoveredShape = null;
      }
    },
    detectVenueFromMapPosition(point: mapBoxGl.Point) {
      if (!this.showVenuePopup) return null;

      const features = this.map?.queryRenderedFeatures(point, {
        layers: mapboxConfig.venueLayerIds,
      });

      return features?.length ? features[0] : null;
    },
    openVenuePopup(venueLngLat: mapBoxGl.LngLat) {
      this.map?.flyTo({
        center: venueLngLat,
        duration: 250,
        offset: [0, -40],
      });

      const venuePopupRef = this.$refs?.venuePopup as Vue;
      this.venuePopup = new mapBoxGl.Popup(this.popupOptions).setLngLat(venueLngLat).setDOMContent(venuePopupRef.$el);
      if (this.map) {
        this.venuePopup.addTo(this.map);
      }
    },
    closeVenuePopup() {
      if (this.venuePopup) {
        this.venuePopup.remove();
        this.venuePopup = null;
        this.clickedVenueId = null;
        this.clickedVenueSource = null;
      }
    },
    includeVenue(venue: Venue) {
      const geoTarget: GeoTarget = this.createGeoTargetFromClickedVenue(venue, true);
      this.emitVenueAction(geoTarget);
      this.closeVenuePopup();
    },
    excludeVenue(venue: Venue) {
      const geoTarget: GeoTarget = this.createGeoTargetFromClickedVenue(venue, false);
      this.emitVenueAction(geoTarget);
      this.closeVenuePopup();
    },
    createGeoTargetFromClickedVenue(venue: Venue, isIncluded: boolean): GeoTarget {
      return {
        geolocation: {
          geography: {
            geoJSON: null,
            geoJSONFileUrl: null,
            latitude: venue.latitude,
            longitude: venue.longitude,
          },
          label: venue.name,
          type: 'venue',
          value: venue.id.toString(),
        },
        isIncluded,
        radius: 'Venue Only',
      };
    },
    emitVenueAction(geoTarget: GeoTarget) {
      if (this.selectedVenueGeoTargetIndex > -1) {
        this.$emit('geoTargetUpdated', geoTarget, this.selectedVenueGeoTargetIndex);
        this.$emit('geoTargetUnselected');
      } else {
        this.$emit('geoTargetAdded', geoTarget);
      }
    },
    isAddingExcludedVenue(newGeoTargets: GeoTarget[], oldGeoTargets: GeoTarget[]) {
      if (newGeoTargets.length === oldGeoTargets.length + 1) {
        const newGeoTarget = newGeoTargets[newGeoTargets.length - 1];
        return newGeoTarget.geolocation.type === 'venue' && !newGeoTarget.isIncluded;
      } else {
        return false;
      }
    },
    openGeoTargetPopup(lngLat: mapBoxGl.LngLat) {
      if (this.focusedShape) {
        this.$nextTick(() => {
          const geoTargetPopupRef = this.$refs?.geoTargetPopup as Vue | undefined;
          if (geoTargetPopupRef) {
            this.geoTargetPopup = new mapBoxGl.Popup(this.popupOptions).setLngLat(lngLat).setDOMContent(geoTargetPopupRef.$el);
            if (this.map) this.geoTargetPopup.addTo(this.map);
          }
        });
      }
    },
    closeGeoTargetPopup() {
      if (this.geoTargetPopup) this.geoTargetPopup.remove();
    },
    onGeoTargetPopupExpanded() {
      if (!this.focusedShape || !this.geoTargetPopup || !this.map) return;

      this.map.easeTo({
        center: this.geoTargetPopup.getLngLat(),
        duration: 250,
        offset: [0, -112],
      });
    },
    onGeoTargetPopupCancel() {
      this.unfocusSelectedShape();
      this.emitFocusedGeoTargetAsSelected();
    },
    onGeoTargetPopupRemove() {
      this.$emit('geoTargetRemoved', this.geoTargets[this.focusedGeoTargetIndex], this.focusedGeoTargetIndex);
      this.unfocusSelectedShape();
      this.emitFocusedGeoTargetAsSelected();
    },
    onGeoTargetPopupUpdate(updatedGeoTarget: GeoTarget) {
      this.$emit('geoTargetUpdated', updatedGeoTarget, this.focusedGeoTargetIndex);
      this.unfocusSelectedShape();
      this.emitFocusedGeoTargetAsSelected();
    },
    onFilterOnMoveChange(isChecked: boolean) {
      this.$emit('filterOnMoveChecked', isChecked);
    },
  },
});
</script>

<style scoped>
.map-wrapper {
  position: relative;
}

.overlay {
  position: absolute;
  z-index: 1;
}

.right {
  right: 0;
}

.bottom {
  bottom: 0;
}

.popup-container {
  position: absolute;
  top: -50px;
  left: -7500px;
}
</style>

<style>
.inventory-map-popup .mapboxgl-popup-content {
  padding: 0;
  font-family: 'Roboto', sans serif;
}
</style>
