<template lang="pug">
div
  v-card
    v-card-title.text-h5 Bulk Upload

    div.step-1(v-if='atStep === 1')
      v-card-text
        div Copy and paste a list of locations below.
        div#max-entries-label Each entry must be on a separate line, delimited by a line break. Maximum {{ maxEntries.toLocaleString() }} entries.

        div.mb-0.mt-4
          v-item-group(mandatory @change='locationTypeSelectionChanged' :value='locationTypeSelectionKey')
            template(v-for='locationType in generateLocationTypes')
              v-item.locationTypeItem(v-if='locationType.isVisible' :key='locationType.key' :class='locationType.key' v-slot='{ active, toggle }' :value='locationType.key')
                v-chip.mr-1(
                  small
                  :dark='active'
                  :color="active ? 'primary' : 'blue-grey lighten-5'"
                  :input-value='active'
                  @click='toggle'
                )
                  .text {{ locationType.label }}

        v-textarea(
          id='bulk-upload-textarea'
          ref='bulk-upload-textarea'
          auto-grow
          class='mt-6'
          clearable
          filled
          v-model='bulkText'
          :placeholder='selectedLocationType.placeholderText'
          style='overflow-y:auto; max-height: 50vh'
        )
      v-card-actions
        v-spacer
        v-btn.bulk-upload-cancel(
          text
          @click='cancelBulkUpload'
        ) Cancel
        v-btn(
          id='bulk-upload-submit'
          color='primary'
          text
          :disabled='!bulkText'
          @click='processGeolocations'
        ) Match locations

    div.step-2(v-if='atStep === 2')
      v-card-text.pb-0
        .mb-2
          span.results-summary Found {{ numValidResults }} valid locations out of {{ bulkItems.length }} records.
          a.clear-all(@click='clearAll()')  Clear All
        v-container(fluid)
          v-layout(align-end justify-space-between row)
            v-flex
              .default-radius-address(v-if="filteredBulkItems.map(x => x.geolocation ? x.geolocation.type : null).includes('address')" style="height:30px;")
                span.label Default Radius for Addresses
                SelectRadius(
                  geoType="address"
                  :value="defaultRadius.address"
                  :unit="unit"
                  @input="val => updateRadius('address', val)"
                )
              .default-radius-city(v-if="filteredBulkItems.map(x => x.geolocation ? x.geolocation.type : null).includes('city')" style="height:30px;")
                span.label Default Radius for Cities
                SelectRadius(
                  geoType="city"
                  :is-geo-selection-disabled-func="async () => true"
                  :value="defaultRadius.city"
                  :unit="unit"
                  @input="val => updateRadius('city', val)"
                )
              .default-radius-venue(v-if="filteredBulkItems.map(x => x.geolocation ? x.geolocation.type : null).includes('venue')" style="height:30px;")
                span.label Default Radius for Venues
                SelectRadius(
                  geoType="venue"
                  :value="defaultRadius.venue"
                  :unit="unit"
                  @input="val => updateRadius('venue', val)"
                )
            v-flex.text-md-right
              span(class="my-2")
                | Filter Results
                v-btn-toggle(v-model='selectedStatusFilterIdx' active-class='filter-active' class='mx-2')
                  v-btn.filter-all(small text)
                    span All
                  v-btn.filter-valid(small text)
                    v-icon(color='success' small class='mx-1') mdi-check-circle
                    span Valid
                  v-btn.filter-error(small text)
                    v-icon(color='error' small class='mx-1') mdi-minus-circle
                    span Error

        v-data-table.bulk-results-table(
          ref='bulk-results-table'
          :headers='headers'
          :items='visibleBulkItems'
          :loading="bulkSearchLoading"
          hide-default-footer
          disable-pagination
          item-key='name'
          dense
          fixed-header
          :height="filteredBulkItems.length > 10 ? '55vh' : '100%'"
          mobile-breakpoint='0'
          :custom-sort='sortResultTable'
        )

          template(v-if="resultTableOptions.startIdx > 0" v-slot:body.prepend="{}")
            tr
              td(:colspan="headers.length" :style="'padding-top:' + resultTableStartPaddingHeight + 'px'")
          
          template(v-if="resultTableOptions.startIdx + resultTableOptions.perPage < filteredBulkItems.length" v-slot:body.append="{}")
            tr
              td(:colspan="headers.length" :style="'padding-top:' + resultTableEndPaddingHeight + 'px'")

          template(v-slot:item.status="{ item }")
            v-progress-circular.row-loading(indeterminate color="grey lighten-2" size=24 v-if="item.status === 'pending'")
            v-icon.row-valid(color='success' v-if="item.status === 'valid'") mdi-check-circle
            v-icon.row-error(color='red lighten-1' v-if="item.status === 'error'") mdi-minus-circle

          template(v-slot:item.entry="{ item }")
            div.result-table-cell(
              v-for="label in [item.entry]"
              :title="label" 
              :style="{ width: isScreenLocationTypeSelected ? '15vw' : '20vw' }"
            )
              .open-edit-entry-dialog.primary--text(@click="openEditDialog(item)" style="cursor: pointer;") {{ label }}

          template(v-slot:item.type="{ item }")
            div.result-table-cell(
              v-for="label in [capitalize(formatGeoType(item.geolocation ? item.geolocation.type : null, item.entry))]"
              :title="label" 
              style="width: 5vw"
            ) {{ label }}

          template(v-slot:item.geolocation="{ item }")
            div.result-table-cell(
              v-for="label in [item.geolocation ? item.geolocation.label : '']"
              :title="label" 
              style="width: 22vw"
            ) {{ label }}

          template(v-slot:item.publisher="{ item }")
            div.result-table-cell(
              v-for="label in [item.properties && item.properties.Publisher ? item.properties.Publisher : '']"
              :title="label"  
              style="width: 8vw"
            ) {{ label }}

          template(v-slot:item.faceProviderData="{ item }")
            div.result-table-cell(
              v-for="label in [item.properties && item.properties.FaceProviderData ? item.properties.FaceProviderData : '']"
              :title="label"  
              style="width: 10vw"
            ) {{ label }}

          template(v-slot:item.faceName="{ item }" )
            div.result-table-cell(
              v-for="label in [item.properties && item.properties.FaceName ? item.properties.FaceName : '']" 
              :title="label"  
              style="width: 12vw"
            ) {{ label }}  

          template(v-slot:item.delete="{ item }")
            v-btn.row-delete-btn(icon @click="deleteEntry(item)" max-height="30px" max-width="30px")
              v-icon.row-delete-icon(color='grey') mdi-close

      v-card-actions
        v-spacer
        p.text-caption.mt-4.mr-4 Duplicate entries will be ignored
        v-btn.bulk-upload-cancel(text @click='cancelBulkUpload') Cancel
        v-btn(
          id='bulk-upload-savebtn'
          color='primary'
          text
          :disabled='bulkSearchLoading || !numValidUniqueResults'
          @click='bulkAdd'
        ) Add {{ numValidUniqueResults }} locations

  v-dialog(max-width=900 v-model='isEditDialogOpen')
    v-card.edit-entry-dialog(v-if='isEditDialogOpen')
      v-card-title.edit-entry-title Edit: {{ editedItem.entry }}
      v-card-text
        GeoAutocomplete.my-3(
          :clear-field-upon-select='false'
          :geolocations="editedItemSearchResults"
          :initial-value='editedItem.entry'
          :label='textareaLabel'
          :loading='editedItemSearchLoading'
          :show-bulk-upload='false'
          :max-bulk-upload-entries='maxEntries'
          @geolocationSelected='updateEditedEntry'
          @search='searchLocations'
        )
      v-card-actions
        v-spacer
        v-btn.edit-entry-cancel(text @click="closeEditDialog") Cancel
        v-btn.edit-entry-save(
          id='bulk-upload-editbtn'
          color='primary'
          text
          :disabled='!editedItemGeolocation'
          @click='saveEditedEntry'
        ) Save

</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { DataTableHeader } from 'vuetify';
import { debounce } from 'lodash';

import GeoAutocomplete from '@/components/GeoAutocomplete.vue';
import SelectRadius from '@/components/SelectRadius.vue';

import { capitalize } from '@/filters';

import GeoService from '@/services/GeoService';
import RadiusService from '@/services/RadiusService';
import CanceledErrorService from '@/services/CanceledErrorService';

import {
  allUnits,
  GeolocationType,
  GeoTarget,
  LocationApiPoco,
  Unit,
  BulkUploadBatchingOptions,
  ResolveGeoJsonFunc,
} from '@/types/GeolocationTypes';
import { CancelableRequest } from '@/types/CancelableRequest';

type BulkItemStatus = 'pending' | 'valid' | 'error';

interface BulkItem {
  entry: string;
  geolocation: LocationApiPoco | null;
  status: BulkItemStatus;
  properties?: { [key in string]?: string } | null;
}

interface BulkUploadLocationType {
  key: GeolocationType | 'coordinates';
  label: string;
  placeholderText: string;
  isVisible: boolean;
}

export default defineComponent({
  name: 'GeoBulkUpload',
  components: {
    GeoAutocomplete,
    SelectRadius,
  },
  props: {
    searchFunc: {
      type: Function as PropType<
        (query: string, count: number, locationType?: GeolocationType) => CancelableRequest<LocationApiPoco[]>
      >,
      required: true,
    },
    batchSearchFunc: {
      type: Function as PropType<
        (queries: { id: number; query: string }[], locationType: GeolocationType) => Promise<{ [key in number]: LocationApiPoco }>
      >,
      default: null,
    },
    batchOptions: {
      type: Object as PropType<{
        inventory: BulkUploadBatchingOptions;
        geocoded: BulkUploadBatchingOptions;
        address: BulkUploadBatchingOptions;
      }>,
      default: () => ({
        inventory: {
          batchSize: 100,
          numberOfParallelCalls: 10,
        },
        geocoded: {
          batchSize: 20,
          numberOfParallelCalls: 5,
        },
        address: {
          batchSize: 20,
          numberOfParallelCalls: 5,
        },
      }),
    },
    resolveGeoJsonFunc: {
      type: Function as PropType<ResolveGeoJsonFunc>,
      required: true,
    },
    postcodeLabel: {
      type: String,
      default: 'Postal Code',
      validator: (val: string) => {
        return ['Postal Code', 'Postcode', 'Zip Code'].includes(val);
      },
    },
    stateLabel: {
      type: String,
      default: 'State',
      validator: (val: string) => ['Province', 'State'].includes(val),
    },
    textareaLabel: {
      type: String,
      default: 'Add addresses, cities, lat/long, or venues',
    },
    unit: {
      type: String as PropType<Unit>,
      validator: (val: string) => allUnits.includes(val as Unit),
      default: 'metric' as Unit,
    },
    maxEntries: {
      type: Number,
      validator: (value: number) => value > 0,
      default: 250,
    },
    canBulkUploadScreenIds: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    cancelBulkUpload: () => true,
    bulkAdd: (_bulkItems: GeoTarget[]) => true,
    /* eslint-enable @typescript-eslint/no-unused-vars */
  },
  data() {
    const defaultRadius: { [key in GeolocationType]?: string } = {
      address: RadiusService.defaultRadiuses('address', this.unit),
      city: RadiusService.defaultRadiuses('city', this.unit),
      venue: RadiusService.defaultRadiuses('venue', this.unit),
    };

    return {
      atStep: 1,
      selectedStatusFilterIdx: 0,

      defaultRadius,

      bulkText: '',
      bulkItems: [] as Array<BulkItem>,
      bulkSearchLoading: false,

      isEditDialogOpen: false,
      editedItem: null as BulkItem | null,
      editedItemGeolocation: null as LocationApiPoco | null,
      editedItemSearchLoading: false,
      editedItemSearchResults: [] as Array<LocationApiPoco>,
      cancelPendingGeoAutocompleteRequest: null as (() => void) | null,
      destroyed: false,

      locationTypeSelectionKey: 'address' as GeolocationType | 'coordinates',

      resultTableOptions: {
        rowHeight: 32,
        perPage: 25,
        startIdx: 0,
        sortBy: '' as string,
        sortDesc: false as boolean,
      },
    };
  },
  computed: {
    filteredBulkItems(): Array<BulkItem> {
      switch (this.selectedStatusFilterIdx) {
        case 1:
          return this.bulkItems.filter((x: BulkItem) => x.status === 'valid');
        case 2:
          return this.bulkItems.filter((x: BulkItem) => x.status === 'error');
        default:
          return this.bulkItems;
      }
    },

    sortedFilteredBulkItems(): Array<BulkItem> {
      const copyOfFilteredBulkItems = [...this.filteredBulkItems];

      if (this.resultTableOptions.sortBy?.length > 0) {
        copyOfFilteredBulkItems.sort((a: BulkItem, b: BulkItem) => {
          let comparisonResult = 0;

          const valueA = this.extractValueFromBulkItem(this.resultTableOptions.sortBy, a) ?? '';
          const valueB = this.extractValueFromBulkItem(this.resultTableOptions.sortBy, b) ?? '';

          if (valueA > valueB) comparisonResult = 1;
          else if (valueA < valueB) comparisonResult = -1;

          return this.resultTableOptions.sortDesc ? comparisonResult * -1 : comparisonResult;
        });
      }

      return copyOfFilteredBulkItems;
    },

    visibleBulkItems(): Array<BulkItem> {
      return this.sortedFilteredBulkItems.slice(
        this.resultTableOptions.startIdx,
        this.resultTableOptions.perPage + this.resultTableOptions.startIdx
      );
    },
    resultTableStartPaddingHeight() {
      return this.resultTableOptions.startIdx * this.resultTableOptions.rowHeight;
    },
    resultTableEndPaddingHeight() {
      return (
        this.resultTableOptions.rowHeight *
        (this.filteredBulkItems.length - this.resultTableOptions.startIdx - this.resultTableOptions.perPage)
      );
    },
    validResults() {
      return this.bulkItems.filter((x) => x.status === 'valid');
    },
    numValidResults() {
      return this.validResults.length;
    },
    numValidUniqueResults() {
      return new Set(this.validResults.map((x) => x.entry)).size;
    },
    selectedLocationType() {
      return this.generateLocationTypes.find((x) => x.key === this.locationTypeSelectionKey);
    },
    isScreenLocationTypeSelected() {
      if (this.selectedLocationType) {
        return ['face_providerdata', 'face'].includes(this.selectedLocationType.key);
      }
      return false;
    },
    generateLocationTypes() {
      return [
        {
          label: 'Addresses',
          key: 'address',
          placeholderText: 'Add Addresses',
          isVisible: true,
        },
        {
          label: this.stateLabel + 's',
          key: 'state',
          placeholderText: 'Add ' + this.stateLabel + 's',
          isVisible: true,
        },
        {
          label: 'Cities',
          key: 'city',
          placeholderText: 'Add Cities',
          isVisible: true,
        },
        {
          label: this.postcodeLabel + 's',
          key: 'postal_code',
          placeholderText: 'Add ' + this.postcodeLabel + 's',
          isVisible: true,
        },
        {
          label: 'Venues',
          key: 'venue',
          placeholderText: 'Add Venues',
          isVisible: true,
        },
        {
          label: 'Screen IDs',
          key: 'face_providerdata',
          placeholderText: 'Add Screen IDs',
          isVisible: this.canBulkUploadScreenIds,
        },
        {
          label: 'Screen Names',
          key: 'face',
          placeholderText: 'Add Screen Names',
          isVisible: true,
        },
        {
          label: 'Lat / Long',
          key: 'coordinates',
          placeholderText: 'Add Lat / Long',
          isVisible: true,
        },
      ] as Array<BulkUploadLocationType>;
    },
    headers() {
      let headers: Array<DataTableHeader> = [
        { text: 'Status', align: 'center', sortable: false, value: 'status' },
        { text: 'Entry', align: 'start', value: 'entry' },
      ];

      if (this.isScreenLocationTypeSelected) {
        headers = headers.concat([
          { text: 'Publisher', align: 'start', value: 'publisher' },
          { text: 'Screen ID', align: 'start', value: 'faceProviderData' },
          { text: 'Screen Name', align: 'start', value: 'faceName' },
        ]);
      } else {
        headers = headers.concat([
          { text: 'Type', align: 'start', value: 'type' },
          { text: 'Complete Geolocation', align: 'start', value: 'geolocation' },
        ]);
      }

      headers.push({ text: '', align: 'end', sortable: false, value: 'delete' });
      return headers;
    },
  },
  watch: {
    selectedStatusFilterIdx() {
      this.resetResultTableScroll();
    },
  },
  created() {
    this.onResultTableScroll = debounce(this.onResultTableScroll, 10);
  },
  destroyed() {
    this.destroyed = true;
  },
  methods: {
    cancelBulkUpload() {
      this.clearAll();
      this.$emit('cancelBulkUpload');
    },
    async processGeolocations() {
      this.bulkSearchLoading = true;
      this.atStep = 2;

      this.$nextTick(() => {
        const resultTableWrapper = this.getResultTableWrapperFromDom();
        resultTableWrapper?.addEventListener('scroll', this.onResultTableScroll);
      });

      this.bulkItems = this.extractBulkItems(this.bulkText);
      if (this.bulkItems.length) {
        await this.batchProcessGeolocations(this.bulkItems);
      }

      this.bulkSearchLoading = false;
    },
    extractBulkItems(search: string) {
      const items = search.split('\n').filter((x) => x.trim().length > 0);
      return [...items].slice(0, this.maxEntries).map((x): BulkItem => {
        return {
          entry: x,
          geolocation: null,
          status: 'pending',
          properties: null,
        };
      });
    },
    async batchProcessGeolocations(bulkItems: BulkItem[]) {
      if (this.locationTypeSelectionKey === 'coordinates') {
        bulkItems.forEach((bulkItem) => {
          try {
            this.setBulkItemGeolocationSearchResult(bulkItem, this.generateLocationFromCoordinatesEntry(bulkItem.entry));
          } catch (error) {
            bulkItem.status = 'error';
          }
        });
        return;
      }

      if (this.batchSearchFunc !== null) {
        await this.batchProcessGeolocationsWithBatchSearch(bulkItems);
      } else {
        await this.batchProcessGeolocationsWithSingleSearch(bulkItems);
      }
    },
    async batchProcessGeolocationsWithSingleSearch(bulkItems: BulkItem[]) {
      if (this.destroyed || !bulkItems.length) return;

      const batchSize = 10;
      const batchItems = bulkItems.slice(0, batchSize).map(async (bulkItem) => {
        try {
          if (this.locationTypeSelectionKey === 'coordinates') throw new Error();
          const result = await this.searchFunc(bulkItem.entry, 1, this.locationTypeSelectionKey).response;
          this.setBulkItemGeolocationSearchResult(bulkItem, result);
        } catch (error) {
          bulkItem.status = 'error';
        }
      });

      await Promise.all(batchItems);
      await this.batchProcessGeolocationsWithSingleSearch(bulkItems.slice(batchSize));
    },
    async batchProcessGeolocationsWithBatchSearch(bulkItems: BulkItem[]) {
      const options = this.resolveBatchingOptions();

      while (!this.destroyed && bulkItems.length) {
        const batches: BulkItem[][] = [];
        for (let i = 0; i < options.numberOfParallelCalls; i++) {
          if (!bulkItems.length) break;
          const batchItems = bulkItems.slice(0, options.batchSize);
          batches.push(batchItems);
          bulkItems = bulkItems.slice(options.batchSize);
        }

        const searchPromises = batches.map(this.batchSearch);

        await Promise.all(searchPromises);
      }
    },
    async batchSearch(batchItems: BulkItem[]) {
      try {
        if (this.locationTypeSelectionKey === 'coordinates') throw new Error();
        const batchItemsForApi = batchItems.map((bulkItem, idx) => {
          return { id: idx, query: bulkItem.entry };
        });
        const results = await this.batchSearchFunc(batchItemsForApi, this.locationTypeSelectionKey);

        batchItemsForApi.forEach((item) => {
          const result = results && item.id in results ? [results[item.id]] : [];
          this.setBulkItemGeolocationSearchResult(batchItems[item.id], result);
        });
      } catch (error) {
        batchItems.forEach((bulkItem) => {
          bulkItem.status = 'error';
        });
      }
    },
    resolveBatchingOptions() {
      if (this.isScreenLocationTypeSelected || this.locationTypeSelectionKey === 'venue') return this.batchOptions.inventory;
      if (this.locationTypeSelectionKey === 'address') return this.batchOptions.address;
      return this.batchOptions.geocoded;
    },
    generateLocationFromCoordinatesEntry(entry: string) {
      const coordinates = GeoService.tryParseCoordinates(entry);
      return coordinates ? [GeoService.generateLocationFromCoordinates(coordinates)] : [];
    },
    setBulkItemGeolocationSearchResult(bulkItem: BulkItem, result: LocationApiPoco[]) {
      if (result.length) {
        bulkItem.geolocation = result[0];
        bulkItem.status = 'valid';
        bulkItem.properties = result[0]?.properties;
      } else {
        bulkItem.status = 'error';
      }
    },
    clearAll() {
      this.bulkItems = [];
      this.bulkText = '';
      this.atStep = 1;
    },
    updateRadius(key: GeolocationType, val: string) {
      if (this.defaultRadius[key]) {
        this.defaultRadius[key] = val;
      }
    },
    openEditDialog(item: BulkItem) {
      this.editedItem = item;
      this.isEditDialogOpen = true;
    },
    closeEditDialog() {
      this.editedItem = null;
      this.editedItemGeolocation = null;
      this.isEditDialogOpen = false;
    },
    async searchLocations(query: string) {
      this.editedItemSearchLoading = true;
      this.cancelPendingGeoAutocompleteRequest?.();

      if (this.locationTypeSelectionKey === 'coordinates') {
        const coordinates = GeoService.tryParseCoordinates(query);
        this.editedItemSearchResults = coordinates ? [GeoService.generateLocationFromCoordinates(coordinates)] : [];
        this.cancelPendingGeoAutocompleteRequest = null;
        this.editedItemSearchLoading = false;
      } else {
        try {
          const { response, cancel } = this.searchFunc(query, 7, this.locationTypeSelectionKey);
          this.cancelPendingGeoAutocompleteRequest = cancel;
          this.editedItemSearchResults = await response;
          this.editedItemSearchLoading = false;
        } catch (e) {
          if (!CanceledErrorService.isCanceledError(e)) {
            this.editedItemSearchLoading = false;
          }
          this.editedItemSearchResults = [];
        }
      }
    },
    updateEditedEntry(newGeolocation: LocationApiPoco) {
      this.editedItemGeolocation = newGeolocation;
    },
    saveEditedEntry() {
      if (this.editedItemGeolocation?.type) {
        const entryIndex = this.bulkItems.findIndex((x: BulkItem) => x.entry === this.editedItem?.entry);

        this.bulkItems[entryIndex].geolocation = this.editedItemGeolocation;
        this.bulkItems[entryIndex].status = 'valid';
        this.bulkItems[entryIndex].entry = this.editedItemGeolocation.label;
        this.bulkItems[entryIndex].properties = this.editedItemGeolocation.properties;
      }

      this.closeEditDialog();
    },
    deleteEntry(row: BulkItem) {
      const idx = this.bulkItems.findIndex((x: BulkItem) => x.entry === row.entry);
      if (idx >= 0) this.bulkItems.splice(idx, 1);
      if (!this.bulkItems.length) this.clearAll();
    },
    async bulkAdd() {
      const validItemsWithRadius: GeoTarget[] = [];

      for (const bulkItem of this.bulkItems) {
        if (bulkItem.status === 'valid' && bulkItem.geolocation !== null) {
          const geoJSON =
            bulkItem.geolocation.geography.geoJSON ??
            (GeoService.shouldPreloadGeoJson(bulkItem.geolocation.type)
              ? await this.resolveGeoJsonFunc(bulkItem.geolocation.geography.id, bulkItem.geolocation.geography.geoJSONFileUrl)
              : null);

          const itemDefaultRadius = bulkItem.geolocation ? this.defaultRadius[bulkItem.geolocation.type] : undefined;

          const geoTarget: GeoTarget = {
            geolocation: {
              ...bulkItem.geolocation,
              geography: {
                latitude: bulkItem.geolocation.geography.latitude,
                longitude: bulkItem.geolocation.geography.longitude,
                geoJSONFileUrl: bulkItem.geolocation.geography.geoJSONFileUrl,
                geoJSON,
              },
            },
            isIncluded: true,
            radius: itemDefaultRadius,
          };

          validItemsWithRadius.push(geoTarget);
        }
      }

      const uniqueItems = [
        ...new Map(
          validItemsWithRadius.map((geoTarget: GeoTarget) => [
            geoTarget.geolocation?.value?.length ? geoTarget.geolocation?.value : geoTarget.geolocation?.label,
            geoTarget,
          ])
        ).values(),
      ];
      this.$emit('bulkAdd', uniqueItems);
      this.clearAll();
    },
    formatGeoType(type: GeolocationType | null, val: string | null = null) {
      if (type === 'state') return this.stateLabel;
      else if (type === 'postal_code') return this.postcodeLabel;
      else if (type === 'address' && val && GeoService.tryParseCoordinates(val)) return 'Coordinates';
      else return type;
    },
    locationTypeSelectionChanged(locationTypeKey: GeolocationType | 'coordinates') {
      this.locationTypeSelectionKey = locationTypeKey;
    },
    onResultTableScroll(e: Event) {
      if (!e.target) return;
      const scrollTop = (e.target as Element).scrollTop;
      this.resultTableOptions.startIdx = Math.floor(scrollTop / this.resultTableOptions.rowHeight);
    },
    sortResultTable(_: BulkItem[], sortBy: string[], sortDesc: boolean[]) {
      if (sortBy[0] !== this.resultTableOptions.sortBy || sortDesc[0] !== this.resultTableOptions.sortDesc) {
        this.resetResultTableScroll();
        this.resultTableOptions.sortBy = sortBy[0];
        this.resultTableOptions.sortDesc = sortDesc[0];
      }

      return this.visibleBulkItems;
    },
    getResultTableWrapperFromDom(): Element | null {
      return this.$el.querySelector('.bulk-results-table .v-data-table__wrapper');
    },
    resetResultTableScroll() {
      this.resultTableOptions.startIdx = 0;
      const resultTableWrapper = this.getResultTableWrapperFromDom();
      if (resultTableWrapper) (resultTableWrapper as HTMLElement).scrollTop = 0;
    },
    extractValueFromBulkItem(valueName: string, item: BulkItem): string | GeolocationType | undefined {
      switch (valueName) {
        case 'entry':
          return item.entry;
        case 'type':
          return item.geolocation?.type;
        case 'geolocation':
          return item.geolocation?.label;
        case 'publisher':
          return item.properties?.Publisher;
        case 'faceProviderData':
          return item.properties?.FaceProviderData;
        case 'faceName':
          return item.properties?.FaceName;
      }
    },
    capitalize(text: string) {
      return capitalize(text);
    },
  },
});
</script>

<style>
.bulk-results-table .v-data-table-header th {
  white-space: nowrap;
}

.result-table-cell,
.result-table-cell * {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
