﻿<template lang="pug">
div(:style="activeBackground" :class="{'hoverable': !missingGeoInfo}")
  v-list-item.px-2
    v-list-item-action.action-left.my-0.mr-1
      v-tooltip(top dark color='secondary')
        template(v-slot:activator="{ on }")
          v-btn.geo-type-icon.my-0(@click="toggleInclusion()" :color="geoTarget.isIncluded ? 'primary' : 'grey'" v-on="on" icon small text )
            v-icon {{ iconType }}
        span.text-caption {{ includedToolTip }}
    v-list-item-content(@click="select()" :style="!missingGeoInfo ? 'cursor: pointer' : ''")
      v-list-item-title(:class="{'primary--text': active}")
        span.text-body-2(:class="{'crossed': !geoTarget.isIncluded}")  {{ geoTarget.geolocation.label }}
    v-list-item-action.my-1(v-if="geoTarget.radius")
      select-radius(
        v-if="radius"
        v-model="radius"
        :geo-type="geoTarget.geolocation.type"
        :unit="unit"
        :is-geo-selection-disabled-func="isGeoSelectionDisabled"
        :geo-json-data="geoJsonData"
      )
    v-list-item-action.my-1.ml-1
      v-btn.delete-btn(@click="deleteElement()" icon small)
        v-icon(color="grey") mdi-close
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import SelectRadius from '@/components/SelectRadius.vue';
import { allUnits, GeoJsonData, GeoTarget, ResolveGeoJsonFunc, Unit } from '@/types/GeolocationTypes';
import RadiusService from '@/services/RadiusService';
import GeoService from '@/services/GeoService';
import { VuetifyThemeItem } from 'vuetify/types/services/theme';
import { Feature, FeatureCollection, Geometry, MultiPolygon, Polygon, GeometryCollection } from 'geojson';

export default defineComponent({
  name: 'GeoTargetElement',
  components: { SelectRadius },
  props: {
    geoTarget: {
      type: Object as PropType<GeoTarget>,
      required: true,
    },
    active: {
      type: Boolean,
    },
    unit: {
      type: String as PropType<Unit>,
      validator: (val: string) => allUnits.includes(val as Unit),
      default: 'metric',
    },
    resolveGeoJsonFunc: {
      type: Function as PropType<ResolveGeoJsonFunc>,
      required: true,
    },
  },
  emits: {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    updateRadius: (_value: string) => true,
    updateInclusion: (_value: boolean) => true,
    delete: () => true,
    select: () => true,
    /* eslint-enable @typescript-eslint/no-unused-vars */
  },
  computed: {
    radius: {
      get() {
        return this.geoTarget.radius;
      },
      set(value: string) {
        this.$emit('updateRadius', value);
      },
    },
    isVenue() {
      return this.geoTarget.geolocation.type === 'venue';
    },
    iconType() {
      if (this.geoTarget.isIncluded) {
        return this.isVenue ? 'mdi-map-marker' : 'mdi-pin';
      } else {
        return this.isVenue ? 'mdi-map-marker-off' : 'mdi-pin-off';
      }
    },
    missingGeoInfo() {
      return (
        !this.geoTarget.geolocation.geography.geoJSON &&
        !this.geoTarget.geolocation.geography.geoJSONFileUrl &&
        this.geoTarget.radius &&
        RadiusService.geoRadiuses().includes(this.geoTarget.radius)
      );
    },
    activeBackground() {
      const color = this.colorWithOpacity(this.$vuetify.theme.themes.light.primary, 0.12);

      return this.active ? `background-color: ${color};` : '';
    },
    includedToolTip() {
      return `Targeted location ${this.geoTarget.isIncluded ? 'included' : 'excluded'}. Click to ${
        !this.geoTarget.isIncluded ? 'include' : 'exclude'
      }.`;
    },
    geoJsonData(): GeoJsonData {
      return {
        geoJson: this.geoTarget.geolocation.geography.geoJSON,
        geoJsonFileUrl: this.geoTarget.geolocation.geography.geoJSONFileUrl,
      };
    },
  },
  methods: {
    deleteElement() {
      this.$emit('delete');
    },
    toggleInclusion() {
      this.$emit('updateInclusion', !this.geoTarget.isIncluded);
    },
    select() {
      if (!this.missingGeoInfo) this.$emit('select');
    },
    colorWithOpacity(color: VuetifyThemeItem, opacity: number): string {
      let rgb;

      switch (typeof color) {
        case 'string':
          rgb = this.hexToRgb(color);
          break;
        case 'number':
          rgb = this.hexToRgb('#' + color.toString(16));
          break;
        case 'object':
          if (color.base) rgb = this.hexToRgb(color.base);
      }

      if (rgb) return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;

      return '';
    },
    hexToRgb(hex: string) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    },
    geoJsonContainsPolygon(geoJsonString: string): boolean {
      try {
        return this.containsPolygon(JSON.parse(geoJsonString));
      } catch {
        return false;
      }
    },
    containsPolygon(geoJSON: Feature | FeatureCollection | Geometry | GeometryCollection): boolean {
      const isPolygonOrMultiPolygonType = (geometry: Geometry): geometry is Polygon | MultiPolygon =>
        geometry.type === 'Polygon' || geometry.type === 'MultiPolygon';

      let geometries: Geometry[] = [];
      if ('features' in geoJSON) {
        geometries = geoJSON.features.map((feature) => feature.geometry);
      } else if ('geometry' in geoJSON) {
        geometries = [geoJSON.geometry];
      } else if ('geometries' in geoJSON) {
        geometries = geoJSON.geometries;
      } else {
        geometries = [geoJSON];
      }

      return geometries.some((geometry) => geometry && isPolygonOrMultiPolygonType(geometry));
    },
    async isGeoSelectionDisabled() {
      return !(await GeoService.isGeoSelectionAvailable(this.geoTarget, this.resolveGeoJsonFunc));
    },
  },
});
</script>

<style scoped>
.action-left {
  min-width: 36px;
}

.crossed {
  text-decoration: line-through;
}

.hoverable:hover {
  background-color: rgba(0, 0, 0, 0.03);
}
</style>
