﻿<template lang="pug">
v-card.d-flex.align-center(
  :elevation="elevated ? 2 : 0"
  :height="height"
  :min-width="minWidth"
  style="overflow: hidden;")
  v-menu(bottom offset-y :nudge-bottom="10" open-on-hover max-width="450" open-delay="100")
    template(v-slot:activator="{on}")
      v-card-text.pa-0(v-on="on")
        div(:class="compact ? 'px-2 mb-0' : ''")
          div(v-if="!compact")
            div.d-flex.justify-space-between.align-end.pb-1
              div.overline Est. Budgeted Impressions
              div.overline Total Available
            v-progress-linear.mb-1(
              :value="targetedImpressionsPercentage"
              :indeterminate="loading"
              :height="6"
              :color="progressColor"
              rounded)
          div.d-flex.justify-space-between.align-baseline
            // Left
            div.d-flex
              v-icon.mr-1(:color="budgetedMode ? progressColor : undefined") mdi-account-multiple
              span.text-h6.pb-0.text-no-wrap
                v-scroll-y-reverse-transition(leave-absolute)
                  div(v-if="!loading")
                    span.na.grey--text(v-if="isNA && primaryAlignment === 'left'") N/A
                    span.targeted-impressions.text--secondary(v-else-if="budgetedMode") {{formatNumber(targetedImpressions)}}
                    span.total-impressions.text--secondary(v-else-if="primaryAlignment === 'left'") {{formatNumber(totalImpressions)}}
              div.text-h6 &nbsp;
            // Right
            div
              v-scroll-y-reverse-transition
                div(v-if="!loading")
                  span.na.grey--text.text-h6(v-if="isNA && primaryAlignment === 'right'") N/A
                  span.total-impressions.grey--text.ml-1(v-else-if="budgetedMode") / {{ formatNumber(totalImpressions) }}
                  span.total-impressions.text--secondary.text-h6(v-else-if="primaryAlignment === 'right'") {{ formatNumber(totalImpressions) }}
        v-progress-linear(
          v-if="compact && (budgetedMode || loading)"
          :style="'position: absolute; bottom: 0;'"
          :value="targetedImpressionsPercentage"
          :indeterminate="loading"
          :height="6"
          :color="progressColor"
          rounded)

    // Tooltip
    v-card.tooltip
      v-card-text.text--primary.caption
        div(v-if="isNA") Estimated impressions are not available when using a deal ID or when selected targeting yields no result.
        div(v-else-if="budgetedMode")
          strong Estimated budgeted impressions
          |  and
          strong  total targeted available impressions
          |  are based on historical data. They consider many factors such as, inventory availability, bid pressure, max bid, budget, pricing and markup fee. Estimation can change at any moment without notice.
        div(v-else)
          strong Total targeted available impressions
          |  are based on historical data. It considers many factors such as, inventory availability, bid pressure, max bid, budget, pricing and markup fee. Estimation can change at any moment without notice.
        div.alert.d-flex.mt-2(v-if='thresholdReached')
          v-icon(color="error") mdi-alert
          div.text-caption.pl-2
            | Significant risk of under-delivering impressions. Try to expand targeting selection or date range.
</template>

<script lang="ts">
import NumberHelper from '@/services/NumberHelper';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'ForecastImpressions',
  props: {
    elevated: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    primaryAlignment: {
      type: String as PropType<'left' | 'right'>,
      validator: (val: string) => ['left', 'right'].includes(val),
      default: 'left',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    targetedImpressions: {
      type: [Number, () => null],
      validator: (value: number | null) => !value || value >= 0,
      default: null,
    },
    totalImpressions: {
      type: [Number, () => null],
      validator: (value: number | null) => !value || value >= 0,
      default: null,
    },
  },
  data() {
    return {
      threshold: 80,
    };
  },
  computed: {
    height() {
      return this.compact ? 44 : undefined;
    },
    minWidth() {
      return this.budgetedMode ? 180 : 130;
    },
    isNA() {
      return this.totalImpressions === null;
    },
    budgetedMode() {
      return this.targetedImpressions !== null && this.totalImpressions !== null;
    },
    targetedImpressionsPercentage() {
      if (this.loading || this.totalImpressions === null || this.totalImpressions === 0 || this.targetedImpressions === null)
        return 0;

      return Math.ceil((this.targetedImpressions / this.totalImpressions) * 100);
    },
    progressColor() {
      if (this.targetedImpressionsPercentage === 0) return 'grey';
      else return this.thresholdReached ? 'error' : 'success';
    },
    thresholdReached() {
      return this.targetedImpressionsPercentage >= this.threshold;
    },
  },
  methods: {
    formatNumber(number: number) {
      return NumberHelper.shortenNumber(number, 100000, 1);
    },
  },
});
</script>
<style scoped>
.overline {
  font-size: 0.625rem !important;
  font-weight: 400;
  line-height: 1rem;
}
</style>
