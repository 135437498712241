<template lang="pug">
div
  .pl-0.pt-1.pb-3(v-if='label')
    #label.text-body-2 {{ label }}
    a#documentation-link.info--text.text-decoration-none(v-if='documentationLink' :href='documentationLink' target='_blank')
      small Explore environments
      v-icon(small color='info') mdi-launch
  v-layout#loading-placeholders(v-if='isLoading' row wrap)
    v-flex.pt-0(text-md-left)
      v-skeleton-loader.my-3(max-width='100' height='12' type='text')
      v-skeleton-loader.my-3(max-width='300' type='heading')
      v-skeleton-loader.my-3(max-width='200' type='heading')
      v-skeleton-loader.my-3(max-width='300' type='heading')
      v-skeleton-loader.my-3(max-width='450' type='heading')
      v-skeleton-loader.my-3(max-width='300' type='heading')
      v-skeleton-loader.my-3(max-width='100' height='12' type='text')
      v-skeleton-loader.my-3(max-width='200' type='heading')
      v-skeleton-loader.my-3(max-width='300' type='heading')
      v-skeleton-loader.my-3(max-width='450' type='heading')
      v-skeleton-loader.my-3(max-width='200' type='heading')
      v-skeleton-loader.my-3(max-width='300' type='heading')
  v-treeview#treeview.body-2(
    v-else
    dense
    expand-icon='mdi-chevron-down'
    item-children='children'
    item-key='id'
    item-text='label'
    :items='environmentTargets'
    open-on-click
    :open.sync='openedTargets'
    return-object
    selectable
    selected-color='primary'
    :selection-type='null'
    :value='selectedTargets'
    @input='selectedTargetsChanged'
  )
</template>

<script lang="ts">
import { EnvironmentTarget, Filter, Group, Segment, TaxonomyApiPoco } from '@/types/EnvironmentTypes';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'EnvironmentTarget',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    documentationLink: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    taxonomies: {
      type: Array as PropType<TaxonomyApiPoco[]>,
      required: true,
    },
    labelKey: {
      type: String,
      default: 'label',
    },
    valueKey: {
      type: String,
      default: 'key',
    },
    childrenKey: {
      type: String,
      default: 'children',
    },
    returnAllAsNone: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object as PropType<Segment | null>,
      default: null,
    },
  },
  emits: {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    targetsChecked: (_value: string[]) => true,
    targetsUnchecked: (_value: string[]) => true,
    input: (_value: Segment | null) => true,
    /* eslint-enable @typescript-eslint/no-unused-vars */
  },
  data() {
    return {
      environmentTargets: [] as EnvironmentTarget[],
      selectedTargets: [] as EnvironmentTarget[],
      openedTargets: [] as EnvironmentTarget[],
      isInitializingSelectedTargets: false,
      totalNumberOfTargets: 0,
    };
  },
  watch: {
    taxonomies() {
      this.initializeEnvironmentTargets();
    },
    value() {
      this.setSelectedTargets();
    },
  },
  created() {
    this.initializeEnvironmentTargets();
  },
  methods: {
    initializeEnvironmentTargets() {
      this.environmentTargets = [
        {
          id: 'all',
          label: 'All',
          value: 'all',
          children: this.createEnvironmentTargetsFromTaxonomies(this.taxonomies),
        },
      ];
      this.setSelectedTargets();
      this.openedTargets = this.environmentTargets;
      this.totalNumberOfTargets = this.countTargets(this.environmentTargets);
    },
    createEnvironmentTargetsFromTaxonomies(taxonomies: TaxonomyApiPoco[], parentId?: string) {
      const environmentTargets: EnvironmentTarget[] = [];

      taxonomies.forEach((taxonomy) => {
        const { label, value, children } = this.validateTaxonomy(taxonomy);
        const id = parentId ? `${parentId} - ${label}` : label;

        environmentTargets.push({
          id,
          label,
          value,
          children: this.createEnvironmentTargetsFromTaxonomies(children, id),
        });
      });

      return environmentTargets.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    },
    validateTaxonomy(taxonomy: TaxonomyApiPoco) {
      const label = taxonomy[this.labelKey];
      if (typeof label !== 'string') throw new Error('Taxonomy must have a field matching its label key');

      const value = taxonomy[this.valueKey];
      if (typeof value !== 'string') throw new Error('Taxonomy must have a field matching its value key');

      const children = taxonomy[this.childrenKey];
      if (!Array.isArray(children)) throw new Error('Taxonomy must have a field matching its children key');

      return { label, value, children };
    },
    countTargets(environmentTargets: EnvironmentTarget[]): number {
      return environmentTargets.reduce((count, target) => {
        return count + this.countTargets(target.children) + 1;
      }, 0);
    },
    setSelectedTargets() {
      this.isInitializingSelectedTargets = true;

      if (this.value === null || this.areAllTargetsSelected(this.value)) {
        this.selectedTargets = this.selectAllTargets(this.environmentTargets);
      } else {
        const selectedTargetIds: string[] = this.value.groups.map((group) =>
          group.filters.map((filter) => filter.label).join(' - ')
        );
        this.selectedTargets = this.selectTargetsFromId(this.environmentTargets, selectedTargetIds);
      }

      this.$nextTick(() => {
        this.isInitializingSelectedTargets = false;
      });
    },
    areAllTargetsSelected(segment: Segment) {
      return segment.groups.length === this.taxonomies.length && segment.groups.every((group) => group.filters.length === 1);
    },
    selectAllTargets(environmentTargets: EnvironmentTarget[]) {
      const currentTargets: EnvironmentTarget[] = [];

      environmentTargets.forEach((target) => currentTargets.push(target, ...this.selectAllTargets(target.children)));

      return currentTargets;
    },
    selectTargetsFromId(environmentTargets: EnvironmentTarget[], selectedTargetIds: string[]) {
      const currentTargets: EnvironmentTarget[] = [];

      environmentTargets.forEach((target) => {
        if (selectedTargetIds.includes(target.id)) {
          currentTargets.push(target, ...this.selectAllTargets(target.children));
        } else {
          currentTargets.push(...this.selectTargetsFromId(target.children, selectedTargetIds));
        }
      });

      return currentTargets;
    },
    selectedTargetsChanged(newTargets: EnvironmentTarget[]) {
      if (this.isInitializingSelectedTargets) return;

      this.emitSelectedTargetIds(newTargets);
      this.emitSegment(newTargets);

      this.selectedTargets = newTargets;
    },
    emitSelectedTargetIds(newTargets: EnvironmentTarget[]) {
      const previousTargetIds = this.selectedTargets.filter((target) => target.children.length === 0).map((target) => target.id);
      const newTargetIds = newTargets.filter((target) => target.children.length === 0).map((target) => target.id);

      if (newTargetIds.length > previousTargetIds.length) {
        const targetsChecked = newTargetIds.filter((target) => !previousTargetIds.includes(target));
        this.$emit('targetsChecked', targetsChecked);
      } else {
        const targetsUnchecked = previousTargetIds.filter((target) => !newTargetIds.includes(target));
        this.$emit('targetsUnchecked', targetsUnchecked);
      }
    },
    emitSegment(newTargets: EnvironmentTarget[]) {
      const emitNullSegment = this.returnAllAsNone && newTargets.length === this.totalNumberOfTargets;
      this.$emit('input', emitNullSegment ? null : this.buildSegment(newTargets));
    },
    buildSegment(newTargets: EnvironmentTarget[]) {
      const segment: Segment = {
        groups: this.buildGroups(
          this.environmentTargets[0].children,
          newTargets.map((target) => target.id)
        ),
        operation: 'or',
      };

      return segment;
    },
    buildGroups(environmentTargets: EnvironmentTarget[], newTargetIds: string[], baseFilters: Filter[] = []) {
      let groups: Group[] = [];

      environmentTargets.forEach((environmentTarget) => {
        const currentFilters = this.buildFilters(environmentTarget, baseFilters);

        if (newTargetIds.includes(environmentTarget.id)) {
          groups.push({ filters: currentFilters, operation: 'and' });
        } else {
          groups.push(...this.buildGroups(environmentTarget.children, newTargetIds, currentFilters));
        }
      });

      return groups;
    },
    buildFilters(environmentTarget: EnvironmentTarget, baseFilters: Filter[]) {
      const newFilter: Filter = {
        label: environmentTarget.label,
        value: environmentTarget.value,
        selectionRule: 'include',
      };

      return [...baseFilters, newFilter];
    },
  },
});
</script>

<style scoped>
::v-deep .v-treeview-node__root {
  min-height: 34px;
}
</style>
